import { colors, media, shadow, typo } from "@styles/index";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";

interface IconButtonProps extends PropsWithChildren {
  iconSrc: string;
  link?: string;
}

const IconButton: React.FC<IconButtonProps> = (props) => {
  return (
    <>
      <StyledButton
        {...props}
        onClick={() => {
          if (props.link) {
            window.open(props.link);
          }
        }}
      >
        <StyledIcon backgroundSrc={props.iconSrc} width={24} height={24} />
        <StyledText size={typo.BODY_06M}>{props.children}</StyledText>
      </StyledButton>
    </>
  );
};

export default IconButton;

const StyledButton = styled.button`
  cursor: pointer;
  border: none;
  padding: 16px 35px;
  background: ${colors.WHITE};
  border-radius: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  min-width: 180px;

  ${shadow.DP_8};

  ${media.TABLET} {
    padding: 12px 32px;
    min-width: 160px;
  }
  ${media.MOBILE} {
    padding: 12px 20px;
    min-width: 160px;
  }
`;

const StyledIcon = styled(Icon)`
  ${media.TABLET} {
    width: 20px;
    height: 20px;
  }
`;

const StyledText = styled(Text)`
  ${media.TABLET} {
    ${typo.BODY_08M}
  }
`;
