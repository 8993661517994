import Text from "@components/atoms/Text";
import { colors, media, typo } from "@styles/index";
import React from "react";
import styled from "styled-components";

interface RecruitingTipSectionProps {}

const RecruitingTipSection: React.FC<RecruitingTipSectionProps> = ({}) => {
  const tipList = [
    "건물의 총 주차면수 : 000개",
    "전기차 수 : 모르시면 '모름'으로 적어주세요.",
    "설치를 원하시는 충전기 종류 : 완속(3kW, 7kW, 10kW 멀티 등), 급속(50kW, 100kW, 200kW 등)",
    "현재 설치되어 있는 충전기 종류 : 없다면 '없음'으로 적어주세요.",
    "현재 설치되어 있는 충전기 운영사업자 : 없다면 '없음'으로 적어주세요.",
    "상담내용 : 비용, 보조금, 기간, 요금 등 궁금하신 사항을 적어주세요.",
  ];

  return (
    <TipSection>
      <TipTitle>작성 TIP!</TipTitle>
      <Text size={typo.BODY_07R} color={colors.GRAY_04}>
        다음의 사항을 알려주시면 더 빠르고 정확한 상담이 가능해요.
      </Text>

      <TipListWrap>
        {tipList.map((content, idx) => {
          return (
            <Tip key={`tip-${idx}`}>
              {"•"}
              <Text size={typo.BODY_07R} color={colors.GRAY_04}>
                {content}
              </Text>
            </Tip>
          );
        })}
      </TipListWrap>
    </TipSection>
  );
};

export default RecruitingTipSection;

const TipSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 324px;
  border-radius: 16px;
  background-color: ${colors.GRAY_11};
  padding: 24px;

  ${media.TABLET} {
    max-width: unset;
    border-radius: unset;
    padding: 32px 40px 40px;
  }
  ${media.MOBILE} {
    max-width: unset;
    border-radius: unset;
    padding: 24px 20px 20px;
  }
`;

const TipTitle = styled(Text)`
  color: ${colors.PRIMARY};
  ${typo.HEADING_07};
`;

const TipListWrap = styled.ul``;

const Tip = styled.li`
  ${typo.BODY_07R};
  color: ${colors.GRAY_04};
  display: flex;
  gap: 8px;
`;
