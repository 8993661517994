import {
  requestCorporateSignup,
  requestUpload,
  SignUpCorporateMemberRequestBody,
} from "@apis/signup";
import Button from "@components/atoms/Button";
import CustomInput from "@components/atoms/CustomInput";
import CustomRadio from "@components/atoms/CustomRadio";
import Text from "@components/atoms/Text";
import ContentTitleLabel from "@components/molecule/ContentTitleLabel";
import EmailInput from "@components/molecule/EmailInput";
import FileInput from "@components/molecule/FileInput";
import RadioItem from "@components/molecule/RadioItem";
import Layout from "@components/template/Layout";
import useGlobalModal from "@hooks/useGlobalModal";
import useRouter from "@hooks/useRouter";
import { ContentsSection, ContentsWrap } from "@pages/Notice";

import {
  colors,
  grid,
  media,
  MOBILE_PADDING_VERTICAL,
  typo,
} from "@styles/index";
import BusinessNumberFormat from "@utils/numberFormat";
import phoneNumberFormat from "@utils/phoneNumberFormat";
import dayjs from "dayjs";
import { useFormik } from "formik";
import React from "react";
import { useMutation } from "react-query";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

export type PaymentMethod = "real-time" | "batch-settlement";
export type PaymentMethodOption = "auto-payment" | "manual-payment";

interface FormikInitialValues {
  // 아이디
  loginId: string;

  // 아이디 중복 체크
  // isLoginIdDuplicateCheck: boolean;

  // 비밀번호
  password: string;

  // 비밀 번호 확인
  passwordConfirm: string;

  // 회원명 (본인인증 결과) // 담당자명
  name: string;

  // 휴대폰번호 (본인인증 결과)
  phone: string;

  // 법인명
  corporateName: string;

  // 대표자명
  ownerName: string;

  // 법인명 중복 체크
  // corporateDuplicateCheck: boolean;

  // 사업자 등록번호
  businessNumber: string;

  // 사업자 등록증 파일명 (PDF)
  businessRegistrationFileName?: string;

  // 사업자 등록증 (PDF)
  businessRegistration?: File;

  // 이메일
  emailLocal: string;
  // 이메일 도메인
  emailDomain: string;

  // 담당자 연락처
  contact?: string;

  // 결제 방식
  paymentMethod: PaymentMethod;

  // 사후 별도 정산 옵션
  paymentMethodOption?: PaymentMethodOption;
}

const InitialValues: FormikInitialValues = {
  loginId: "",
  password: "",
  passwordConfirm: "",
  name: "",
  phone: "",
  emailLocal: "",
  emailDomain: "",
  corporateName: "",
  ownerName: "",
  businessNumber: "",
  businessRegistrationFileName: undefined,
  businessRegistration: undefined,
  paymentMethod: "real-time",
  paymentMethodOption: "auto-payment",
};

// 결제 방식 radio 버튼 데이터
const paymentMethodData = [
  {
    label: (
      <RadioItem
        label="법인(공통) 카드 즉시 결제"
        subLabel="* 법인 계정에 소속된 회원이 충전할 때마다 즉시결제됩니다."
      />
    ),
    value: "real-time",
  },
  {
    label: (
      <RadioItem
        label=" 사후 별도 정산"
        subLabel=" * 법인 계정에 소속된 모든 회원의 충전 내역이 월별, 통합 결제됩니다."
      />
    ),
    value: "batch-settlement",
  },
];

const paymentMethodOptionData = [
  {
    label: (
      <RadioItem
        label="자동 결제"
        subLabel="* 이용 익월 15일마다 지정하신 결제수단으로 자동결제됩니다."
      />
    ),
    value: "auto-payment",
  },
  {
    label: (
      <RadioItem
        label="수동 결제"
        subLabel=" * 법인 담당자가 홈페이지에서 직접결제 하셔야 합니다."
      />
    ),
    value: "manual-payment",
  },
];

const CorporateSignup: React.FC = () => {
  const formik = useFormik<FormikInitialValues>({
    initialValues: InitialValues,
    onSubmit: () => {},
  });

  const { showAlert, hideModal, showNotice } = useGlobalModal();
  const { replace } = useRouter();

  const getToday = () => {
    const today = dayjs();
    const year = today.year();
    const month = today.month() + 1;
    const date = today.date();

    return `${year}년 ${month}월 ${date}일`;
  };

  // 이미지 업로드
  const { mutateAsync: handlePDFUpload } = useMutation(requestUpload);
  // 법인 등록
  const { mutateAsync: corporateSignup } = useMutation(requestCorporateSignup, {
    onSuccess: () => {
      showAlert({
        title: `법인 회원가입 신청이\n완료되었어요.`,
        message: `에버온 심사 완료 후 서비스를\n이용하실 수 있어요.\n회원가입 등록일: ${getToday()}`,
        buttonText: "홈으로 가기",
        handleClose: () => {
          replace("/");
          hideModal();
          formik.resetForm();
        },
      });
    },
    onError: (err: any) => {
      if (err && err?.message) {
        showAlert({
          title: err?.message ?? "법인 등록에 실패했어요.",
          message: ``,
          buttonText: "확인",
          isLottie: false,
        });
      }
    },
  });

  // 법인 등록하는 로직
  const handleCorporateSignup = () => {
    const { businessRegistration } = formik.values;

    if (businessRegistration)
      handlePDFUpload(businessRegistration)
        .then((res) => {
          const {
            loginId,
            passwordConfirm,
            name,
            phone,
            corporateName,
            ownerName,
            businessNumber,
            contact,
            emailLocal,
            emailDomain,
            paymentMethod,
            paymentMethodOption,
          } = formik.values;

          const body: SignUpCorporateMemberRequestBody = {
            loginId,
            password: passwordConfirm,
            name,
            phone,
            corporateName,
            ownerName,
            businessNumber,
            businessRegistration: res.filePath,
            businessRegistrationFileName: res.fileName,
            ...(contact && { contact }),
            email: emailLocal + "@" + emailDomain,
            paymentMethod,
            ...(paymentMethod === "batch-settlement" && {
              paymentMethodOption: paymentMethodOption,
            }),
          };

          corporateSignup(body);
        })
        .catch((err: any) =>
          showNotice({
            title: "사진 업로드에 실패했어요.",
            buttonText: "확인",
          }),
        );
  };

  const { values } = formik;
  const {
    corporateName,
    ownerName,
    paymentMethod,
    businessNumber,
    emailDomain,
    emailLocal,
    loginId,
    name,
    password,
    passwordConfirm,
    phone,
    businessRegistration,
  } = values;

  const isSignUpButtonDisabled = () => {
    if (
      corporateName &&
      ownerName &&
      paymentMethod &&
      businessNumber &&
      emailDomain &&
      emailLocal &&
      loginId &&
      name &&
      password &&
      passwordConfirm &&
      phone &&
      businessRegistration
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isMobile = useMediaQuery({ maxWidth: grid.MAX_MOBILE - 1 });

  return (
    <Layout headerWhite>
      <ContentTitleLabel
        title="법인 관리자 회원가입"
        description=" 법인관리자의 회원가입이 가능합니다."
        titleGap="16px"
      />

      <ContentsSection fullWidth flexCenter>
        <StyledContentsWrap>
          {/* 법인 정보 */}
          <InputWrap>
            <InputTitle type="h4" size={typo.HEADING_02}>
              법인 정보
            </InputTitle>

            <CustomInput
              label="법인명"
              type="text"
              name="corporateName"
              placeholder={isMobile ? "법인명 입력" : "법인명을 입력해주세요."}
              value={formik.values.corporateName}
              onChange={(e) => {
                formik.setFieldValue("corporateName", e.target.value);
              }}
              maxLength={20}
            />
            <CustomInput
              label="대표자명"
              type="text"
              name="ownerName"
              placeholder={
                isMobile ? "대표자명 입력" : "대표자명을 입력해주세요."
              }
              value={formik.values.ownerName}
              onChange={(e) => {
                formik.setFieldValue("ownerName", e.target.value);
              }}
              maxLength={20}
            />
          </InputWrap>

          {/* 사업자 번호 */}
          <InputWrap>
            <InputTitle type="h4" size={typo.HEADING_02}>
              사업자 등록 번호
            </InputTitle>

            <CustomInput
              label="사업자 등록번호"
              type="text"
              placeholder={
                isMobile
                  ? "‘-’없이 사업자 등록번호 입력"
                  : "‘-’없이 사업자 등록번호 10자리를 입력해주세요."
              }
              value={formik.values.businessNumber}
              onChange={(e) => {
                const { value } = e.target;

                formik.setFieldValue(
                  "businessNumber",
                  BusinessNumberFormat(value),
                );
              }}
              error={
                businessNumber && businessNumber.length !== 12
                  ? "사업자 등록번호를 올바르게 입력해 주세요."
                  : ""
              }
              maxLength={12}
            />

            <FileInput
              label="사업자 등록증 업로드(PDF)"
              subLabel="* 파일 첨부는 PC웹, 모바일 모두 가능합니다."
              placeholder={
                isMobile
                  ? "사업자등록증 업로드"
                  : "사업자등록증을 업로드 해주세요."
              }
              flexBreakLabel
              value={formik.values.businessRegistrationFileName}
              onChange={(e) => {
                const { files } = e.target;

                if (!files) return;

                if (files?.length) {
                  formik.setFieldValue(
                    "businessRegistrationFileName",
                    files[0].name,
                  );
                  formik.setFieldValue("businessRegistration", files[0]);
                } else {
                  formik.setFieldValue("businessRegistrationFileName", "");
                  formik.setFieldValue("businessRegistration", undefined);
                }
              }}
            />
          </InputWrap>

          {/* 법인 계정 관리자 정보 */}
          <InputWrap>
            <InputTitle type="h4" size={typo.HEADING_02}>
              법인 계정 관리자 정보
            </InputTitle>

            <CustomInput
              label="아이디"
              type="text"
              placeholder={isMobile ? "아이디 입력" : "아이디를 입력해주세요."}
              value={formik.values.loginId}
              onChange={async (e) => {
                const { value } = e.target;
                formik.setFieldValue("loginId", value);
              }}
              maxLength={30}
            />
            <CustomInput
              label="비밀번호"
              type="password"
              placeholder={
                isMobile ? "비밀번호 입력" : "비밀번호를 입력해주세요."
              }
              value={formik.values.password}
              onChange={(e) => {
                formik.setFieldValue("password", e.target.value);
              }}
              maxLength={30}
            />
            <CustomInput
              label="비밀번호 재입력"
              type="password"
              placeholder={
                isMobile
                  ? "비밀번호 한번 더 입력"
                  : "비밀번호를 한번 더 입력해주세요."
              }
              value={formik.values.passwordConfirm}
              error={
                passwordConfirm && password !== passwordConfirm
                  ? "비밀번호가 맞지 않아요."
                  : ""
              }
              onChange={(e) => {
                formik.setFieldValue("passwordConfirm", e.target.value);
              }}
              maxLength={30}
            />
          </InputWrap>

          {/* 정산 담당자 정보 */}
          <InputWrap>
            <InputTitle type="h4" size={typo.HEADING_02}>
              정산 담당자 정보
            </InputTitle>

            <CustomInput
              label="담당자 이름"
              subLabel="* 1인 사업자의 경우 개인회원으로 가입해주세요."
              flexBreakLabel
              type="text"
              placeholder={
                isMobile ? "담당자 이름 입력" : "담당자 이름을 입력해주세요."
              }
              value={formik.values.name}
              onChange={(e) => {
                formik.setFieldValue("name", e.target.value);
              }}
              maxLength={20}
            />
            <CustomInput
              label="담당자 휴대폰"
              type="text"
              placeholder={
                isMobile
                  ? "휴대폰 번호 10자리 또는 11자리를 입력"
                  : "휴대폰 번호 10자리 또는 11자리를 입력해주세요."
              }
              value={formik.values.phone}
              onChange={(e) => {
                const { value } = e.target;
                formik.setFieldValue("phone", phoneNumberFormat(value));
              }}
              error={
                phone && phone.length < 12
                  ? "휴대폰 번호를 정확하게 입력해 주세요."
                  : ""
              }
              maxLength={13}
            />
            <CustomInput
              label="담당자 연락처"
              subLabel="(선택항목)"
              subLabelBold
              type="text"
              placeholder={
                isMobile
                  ? "담당자 연락처 입력"
                  : "담당자 연락처를 입력해주세요."
              }
              value={formik.values.contact}
              onChange={(e) => {
                const { value } = e.target;
                const number = value.replace(/[^-\d]/g, "");

                formik.setFieldValue("contact", number);
              }}
              maxLength={13}
            />
            <EmailInput
              label="담당자 이메일"
              subLabel="* 청구서 및 주요 안내를 보내드려요."
              type="text"
              placeholder={
                isMobile ? "이메일 주소 입력" : "이메일 주소를 입력해주세요."
              }
              defualtEmailDomainValue={formik.values.emailDomain}
              defualtEmailLocalValue={formik.values.emailLocal}
              valueLocal={formik.values.emailLocal}
              valueDomain={formik.values.emailDomain}
              nameLocal="emailLocal"
              nameDomain="emailDomain"
              onChangeLocal={(e) => {
                formik.setFieldValue("emailLocal", e.target.value);
              }}
              onChangeDomain={(e) => {
                if (e?.nativeEvent?.type === "input") {
                  formik.setFieldValue("emailDomain", e.target.value);
                } else {
                  formik.setFieldValue("emailDomain", e);
                }
              }}
              maxLength={20}
            />
          </InputWrap>

          {/* 결제 정보 */}
          <InputWrap>
            <InputTitle type="h4" size={typo.HEADING_02}>
              결제 정보
            </InputTitle>

            <CustomRadio
              title="결제방식"
              options={paymentMethodData}
              radioValue={formik.values.paymentMethod}
              onChange={async (e) => {
                await formik.setFieldValue("paymentMethod", e.target.value);
              }}
            />
            {paymentMethod === "batch-settlement" && (
              <>
                <Line />
                <CustomRadio
                  title="사후 별도 정산"
                  options={paymentMethodOptionData}
                  radioValue={formik.values.paymentMethodOption}
                  onChange={async (e) => {
                    await formik.setFieldValue(
                      "paymentMethodOption",
                      e.target.value,
                    );
                  }}
                />
              </>
            )}

            <Button
              style={{ width: "100%", marginTop: "12px" }}
              btnText="법인 등록하기"
              pcBtnSize="extra"
              textSize={typo.BUTTON_02B}
              disabled={isSignUpButtonDisabled()}
              onClick={handleCorporateSignup}
            />
          </InputWrap>
        </StyledContentsWrap>
      </ContentsSection>
    </Layout>
  );
};

export default CorporateSignup;

const InputTitle = styled(Text)`
  ${media.MOBILE} {
    ${typo.HEADING_05};
    color: ${colors.GRAY_02};
  }
`;

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;

  ${media.MOBILE} {
    gap: 20px;
  }
`;

const StyledContentsWrap = styled(ContentsWrap)`
  display: flex;
  flex-direction: column;
  gap: 80px;

  ${media.MOBILE} {
    padding: ${MOBILE_PADDING_VERTICAL} 0;
    gap: 60px;
  }
`;

const Line = styled.span`
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: ${colors.GRAY_08};
`;
