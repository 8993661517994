import { colors, typo } from "@styles/index";
import { Input } from "antd";
import React from "react";
import styled from "styled-components";

const { TextArea } = Input;

interface AntdTextAreaProps {
  height: number;
  isClear?: boolean;
  placeholder: string;
  padding?: string;
  onChange?: any;
  name?: string;
  value?: string;
}

const AntdTextArea: React.FC<AntdTextAreaProps> = ({
  height,
  isClear,
  placeholder,
  padding = "16px 16px 16px 20px",
  onChange,
  name,
  value,
}) => {
  return (
    <StyeldTextArea
      name={name}
      placeholder={placeholder}
      style={{ height: height, resize: "none", padding: padding }}
      allowClear={isClear}
      onChange={onChange}
      value={value}
    />
  );
};

export default AntdTextArea;

const StyeldTextArea = styled(TextArea)`
  ${typo.BODY_07R};

  :where(.css-dev-only-do-not-override-1vtf12y).ant-input-affix-wrapper,
  :where(.css-dev-only-do-not-override-1vtf12y).ant-input {
    border-radius: 12px;
  }

  :where(.css-dev-only-do-not-override-1vtf12y).ant-input:focus,
  :where(.css-dev-only-do-not-override-1vtf12y).ant-input-focused,
  :where(.css-dev-only-do-not-override-1vtf12y).ant-input:hover {
    border-color: ${colors.PRIMARY};
  }
`;
