import Button from "@components/atoms/Button";
import Text from "@components/atoms/Text";
import BaseLayoutModal from "@components/template/BaseLayoutModal";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import { downloadList } from "@constants/home";
import { CommonModalProps } from "@constants/types";
import {
  colors,
  media,
  MOBILE_PADDING_HORIZON,
  TABLET_PADDING_HORIZON,
  typo,
} from "@styles/index";
import {
  ModalDescription,
  ModalTitle,
  ModalTitleWrap,
} from "@styles/CommonStyled";
import React from "react";
import styled from "styled-components";

interface DownloadFormModalProps extends CommonModalProps {}

const DownloadFormModal: React.FC<DownloadFormModalProps> = ({
  isModalOpen,
  handleModalClose,
}) => {
  const fetchApplicationForm = (fileName: string) => {
    const pdfUrl = process.env.PUBLIC_URL + `/pdfs/${fileName}.pdf`;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = `${fileName}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <BaseLayoutModal
      isOpen={isModalOpen}
      handleModalClose={handleModalClose}
      modalPaddingDisabled
      innerWrapPadding="40px 100px 100px"
    >
      <InnerWrap>
        <ModalTitleWrap>
          <ModalTitle>정부 보조금이 필요하신가요?</ModalTitle>
          <ScreenResizing
            pc={
              <ModalDescription whiteSpace="pre-line">
                {`환경부, 서울시, 환경공단에서 보조금을 지원하고 있습니다.\n해당되는 양식을 다운로드하여 작성해주세요.`}
              </ModalDescription>
            }
            tablet={
              <ModalDescription whiteSpace="pre-line">
                {`환경부, 서울시, 환경공단에서 보조금을 지원하고 있습니다.\n해당되는 양식을 다운로드하여 작성해주세요.`}
              </ModalDescription>
            }
            mobile={
              <ModalDescription whiteSpace="pre-line">
                {`환경부, 서울시, 환경공단에서 보조금을 지원하고 있습니다. 해당되는 양식을 다운로드하여 작성해주세요.`}
              </ModalDescription>
            }
          />
        </ModalTitleWrap>
        <ContentsWrap>
          {downloadList.map(({ key, label }) => {
            return (
              <DownloadItem key={key}>
                <ItemLabel>{label}</ItemLabel>
                <StyledButton
                  pcBtnSize="lager"
                  onClick={() => fetchApplicationForm(key)}
                >
                  다운로드
                </StyledButton>
              </DownloadItem>
            );
          })}
        </ContentsWrap>
      </InnerWrap>
    </BaseLayoutModal>
  );
};

export default DownloadFormModal;

const InnerWrap = styled.div`
  ${media.TABLET} {
    padding: 24px ${TABLET_PADDING_HORIZON};
  }
  ${media.MOBILE} {
    padding: 24px ${MOBILE_PADDING_HORIZON};
  }
`;

const ContentsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px; ;
`;

const DownloadItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 29px 32px;
  background: ${colors.GRAY_11};
  border-radius: 16px;

  ${media.TABLET} {
    padding: 30px 20px;
    height: 84px;
  }
`;

const ItemLabel = styled(Text)`
  ${typo.BODY_04M}

  ${media.TABLET} {
    ${typo.BODY_07M}
  }

  ${media.MOBILE} {
    max-width: 167px;
    word-break: keep-all;
    ${typo.BODY_07M}
  }
`;
const StyledButton = styled(Button)`
  ${media.TABLET} {
    padding: 12px 16px;
  }
`;
