import { ICONS } from "@assets/index";

// TODO 데이터 section 별로 폴더 정리!

export interface StoryList {
  label: string;
  title: string;
  number: number;
}

export const storyList: StoryList[] = [
  {
    label: "설치 충전기",
    title: "기 +",
    number: 40000,
  },
  {
    label: "연간 충전량",
    title: "GWh +",
    number: 150,
  },
  {
    label: "누적 회원",
    title: "명 +",
    number: 180000,
  },
  {
    label: "보조금 사업자 선정",
    title: "년 연속 +",
    number: 8,
  },
];

export interface ServiceList {
  title: string;
  pcDescription: string;
  tabletDescription: string;
  mobileDescripon: string;
  iconSrc: string;
}

export const serviceList: ServiceList[] = [
  {
    title: "실시간 관제시스템",
    pcDescription: `10년의 기술력을 기반으로 모든 충전기를\n 실시간 모니터링하고 원격 제어합니다.`,
    tabletDescription: `10년의 기술력을 기반으로 모든 충전기를 실시간 모니터링하고 원격 제어합니다.`,
    mobileDescripon: `10년의 기술력을 기반으로 모든 충전기를 실시간 모니터링하고 원격 제어합니다.`,
    iconSrc: ICONS.SYSTEM,
  },
  {
    title: "스마트 충전 서비스",
    pcDescription: `스마트폰 앱을 통해 쉽고 간편한 충전경험과\n 다양한 혜택을 제공합니다.`,
    tabletDescription: `스마트폰 앱을 통해 쉽고 간편한 충전경험과 다양한 혜택을 제공합니다.`,
    mobileDescripon: `스마트폰 앱을 통해 쉽고 간편한 충전경험과 다양한 혜택을 제공합니다.`,
    iconSrc: ICONS.CHARGING,
  },
  {
    title: "에버온 크루(Crew)",
    pcDescription: `업계 유일의 사전 방문 점검서비스로\n 최상의 충전기 상태를 유지합니다.`,
    tabletDescription: `업계 유일의 사전 방문 점검서비스로 최상의 충전기 상태를 유지합니다.`,
    mobileDescripon: `업계 유일의 사전 방문 점검서비스로 최상의 충전기 상태를 유지합니다.`,
    iconSrc: ICONS.CREW,
  },
  {
    title: "시간별 요금제",
    pcDescription: `시간에 따른 탄력 요금제 적용으로\n 더 저렴한 심야 충전요금을 제공합니다.`,
    tabletDescription: `시간에 따른 탄력 요금제 적용으로 더 저렴한 심야 충전요금을 제공합니다.`,
    mobileDescripon: `시간에 따른 탄력 요금제 적용으로 더 저렴한 심야 충전요금을 제공합니다.`,
    iconSrc: ICONS.PAYMENT,
  },
  {
    title: "24시간 콜센터",
    pcDescription: `고객님의 문의를 실시간으로 응대하기 위해\n 전문상담원들이 365일 대기하고 있습니다.`,
    tabletDescription: `고객님의 문의를 실시간으로 응대하기 위해 전문상담원들이 365일 대기하고 있습니다.`,
    mobileDescripon: `고객님의 문의를 실시간으로 응대하기 위해 전문상담원들이 365일 대기하고 있습니다.`,
    iconSrc: ICONS.CALL,
  },
  {
    title: "소비자 피해 보상",
    pcDescription: `책임보험을 통해 불의의\n 소비자 피해를 보상해 드립니다.`,
    tabletDescription: `책임보험을 통해 불의의 소비자 피해를 보상해 드립니다.`,
    mobileDescripon: `책임보험을 통해 불의의 소비자 피해를 보상해 드립니다.`,
    iconSrc: ICONS.COMPENSATION,
  },
];

export interface TechCardList {
  iconSrc: string;
  title: string;
  description: string;
}

export const techCardList: TechCardList[] = [
  {
    iconSrc: ICONS.OCPP,
    title: "OCPP",
    description: `에버온은 원격 스마트 관제 서비스를 제공하기 위해 글로벌 개방형 충전기 프로토콜인 OCPP 체계를 운영합니다.`,
  },
  {
    iconSrc: ICONS.LOAD,
    title: "로드밸런싱",
    description: `데이터 기반 에너지관리 솔루션을 통해 완벽한 지능형 충전(Smart Charging) 서비스를 제공하고자 합니다.`,
  },
  {
    iconSrc: ICONS.ESS,
    title: "ESS",
    description: `기존 충전 서비스에 에너지 저장 시스템(Energy Storage System)을 접목한 융복합 충전소를 구현하겠습니다.`,
  },
  {
    iconSrc: ICONS.DATA,
    title: "Big Data",
    description: `양질의 빅데이터를 분석 및 활용하여 충전기 및 관제 서비스 관리는 물론 고객의 최적 충전 경험을 제시합니다.`,
  },
  {
    iconSrc: ICONS.DR,
    title: "DR",
    description: `불균형한 에너지 수급상황을 해소하는 DR(Demand Response) 기술을 통해 ESG 가치를 실현하고자 합니다.`,
  },
  {
    iconSrc: ICONS.PNC,
    title: "PnC",
    description: `전기차 충전시 인증 및 결제가 자동으로 이루어지는 PnC(Plug and Charge) 기술로 고객경험을 혁신하겠습니다.`,
  },
  {
    iconSrc: ICONS.WIFI,
    title: "무선충전",
    description: `에버온은 궁극적으로 무선 충전 기술 도입을 통하여 P&G(Parking & Go) 서비스를 구현하고자 합니다.`,
  },
  {
    iconSrc: ICONS.V2G,
    title: "V2G",
    description: `전기차와 전력망을 연결하는 V2G(Vehicle-to-grid) 기술을 접목한 스마트 그리드 서비스를 지향합니다.`,
  },
  {
    iconSrc: ICONS.ENERGY,
    title: "신 재생에너지",
    description: `미래에는 전력의 원천 또한 중요합니다. 에버온은 재생 에너지를 적극 활용한 신개념 충전 서비스를 연구합니다.`,
  },
  {
    iconSrc: ICONS.BATTERY,
    title: "배터리 건강 진단",
    description: `충전기를 활용한 배터리 성능 및 안전진단 을 통해 스마트한 배터리 관리, 화재예방 등 새로운 가치를 제공하고자 합니다.`,
  },
];

export interface ProductCardList {
  key: ProductKey;
  imageSrc: string;
  label: string;
}

export type ProductKey =
  | "everon_7kW(new)"
  | "multi-charge10"
  | "outlet3"
  | "fast-charging";

export const productCardList: ProductCardList[] = [
  {
    key: "everon_7kW(new)",
    imageSrc: "/images/everon_7kW.png",
    label: "완속충전기 (7kW)",
  },
  {
    key: "multi-charge10",
    imageSrc: "/images/multi-charge10.png",
    label: "멀티 충전기 (10kW)",
  },
  {
    key: "outlet3",
    imageSrc: "/images/outlet3.png",
    label: "콘센트형 충전기 (3kW)",
  },
  {
    key: "fast-charging",
    imageSrc: "/images/fast-charging.png",
    label: "급속 충전기 (100kW)",
  },
];

export interface ChargerInfoData {
  key: ProductKey;
  src: string;
  chargerName: string;
  description: string;
  infos: {
    label: string;
    content: string;
  }[];
}

export const chargerInfoDataList: ChargerInfoData[] = [
  {
    key: "everon_7kW(new)",
    src: "/images/everon_7kW.png",
    chargerName: "완속충전기 (7kW)",
    description: "7인치 터치패널과 상태 표시등이 탑재된 고급형 완속 충전기",
    infos: [
      {
        label: "모델명",
        content: "EVL-1107020A01",
      },
      {
        label: "정격입력",
        content: "AC 220V, 60Hz, 32A, 7.1kW (단상)",
      },
      {
        label: "정격출력",
        content: "AC 220V, 60Hz, 32A, (7kW, 1CH)",
      },
      {
        label: "동작온도",
        content: "-25°C ~ 40°C",
      },
      {
        label: "충전타입",
        content: "AC완속(5핀)",
      },
      {
        label: "크기",
        content: "314(W) x 190(D) x 492(H) mm",
      },
      {
        label: "케이블길이",
        content: "6m",
      },
      {
        label: "디스플레이",
        content: "7” Touch LCD",
      },
      {
        label: "결제",
        content: `회원카드(기본 - ISO14443 A/B, Mifare), 신용카드(옵션 - ISO 7810/16)`,
      },
      {
        label: "방수방진등급",
        content: "IP44",
      },
      {
        label: "인증",
        content: "형식인증, KC 인증, OCPP 1.6 인증",
      },
    ],
  },
  {
    key: "multi-charge10",
    src: "/images/multi-charge10.png",
    chargerName: "멀티 충전기 (10kW)",
    description: `충전기 1대로 전기차 3대까지 동시 충전 가능한 전력분배 기술이 적용된 멀티 충전기`,
    infos: [
      {
        label: "모델명",
        content: "EVL-3J1002A01",
      },
      {
        label: "정격입력",
        content: "AC 220V, 60Hz, 64A or 50A, 10.1kW (단상)",
      },
      {
        label: "정격출력",
        content:
          "AC 220V, 60Hz, 32A(7kW 1CH) or 22.5A(5kW 2CH) or 15A(3.3kW 3CH)",
      },
      {
        label: "동작온도",
        content: "-25°C ~ 40°C",
      },
      {
        label: "충전타입",
        content: "AC완속(5핀)",
      },
      {
        label: "크기",
        content: "293(W) x 140(D) x 475(H) mm",
      },
      {
        label: "케이블길이",
        content: "좌/우 9m, 중앙 6m",
      },
      {
        label: "디스플레이",
        content: "7” Touch LCD",
      },
      {
        label: "결제",
        content: `회원카드(기본 - ISO14443 A/B, Mifare), 신용카드(옵션 - ISO 7810/16)`,
      },
      {
        label: "방수방진등급",
        content: "IP44",
      },
      {
        label: "인증",
        content: "형식인증, KC 인증, OCPP 1.6 인증",
      },
    ],
  },
  {
    key: "outlet3",
    src: "/images/outlet3.png",
    chargerName: "콘센트형 충전기 (3kW)",
    description: "좁은 공간에도 설치할 수 있는 과금형 콘센트 모델",
    infos: [
      {
        label: "모델명",
        content: "EVL-1103000901",
      },
      {
        label: "정격입력",
        content: "220Vac, 14A, 60Hz, 3.0kW (단상)",
      },
      {
        label: "정격출력",
        content: "220Vac, 14A, 60Hz, (3kW, 1CH)",
      },
      {
        label: "동작온도",
        content: "-25°C ~ 40°C",
      },
      {
        label: "충전타입",
        content: "AC완속(5핀), C타입 또는 콘센트형으로 변경 가능(옵션)",
      },
      {
        label: "크기",
        content: "100(W) x 112(D) x 207(H) mm",
      },
      {
        label: "케이블길이",
        content: "6m (C타입 기준)",
      },
      {
        label: "디스플레이",
        content: "14 Segment LED",
      },
      {
        label: "결제",
        content: `회원카드(기본 - ISO14443 A/B, Mifare)`,
      },
      {
        label: "방수방진등급",
        content: "IP44",
      },
      {
        label: "인증",
        content: "형식인증, KC 인증, OCPP 1.6 인증",
      },
    ],
  },
  {
    key: "fast-charging",
    src: "/images/fast-charging.png",
    chargerName: "급속 충전기 (100kW)",
    description: "교통 약자의 이용 편의를 고려한 듀얼 급속 충전기 모델",
    infos: [
      {
        label: "모델명",
        content: "EVF-2D10170A01",
      },
      {
        label: "정격입력",
        content: "380Vac, 60Hz, 167A, 110kW (3상4선식)",
      },
      {
        label: "정격출력",
        content: "150~1000Vdc, 0~200A, 100kW 2CH(동시 충전 시 채널당 50kW)",
      },
      {
        label: "동작온도",
        content: "-25°C ~ 40°C",
      },
      {
        label: "충전타입",
        content: "DC콤보",
      },
      {
        label: "크기",
        content: "1100(W) x 580(D) x 1680(H) mm",
      },
      {
        label: "케이블길이",
        content: "6m (좌/우 동일)",
      },
      {
        label: "디스플레이",
        content: "24“ Touch LCD",
      },
      {
        label: "결제",
        content: `회원카드(기본 - ISO14443 A/B, Mifare), 신용카드(옵션 - ISO 7810/16)`,
      },
      {
        label: "방수방진등급",
        content: "IP44",
      },
      {
        label: "인증",
        content: "형식인증, KC 인증, OCPP 1.6 인증",
      },
    ],
  },
];

export enum ZoneCategories {
  Home = `주거지`,
  Office = "사무실",
  Commercial = "상업시설",
  Parking = "주차시설",
}

export interface ZoneCategoryImages {
  src: string;
  text: string;
  category: ZoneCategories;
}

export const categoryList = [
  {
    categoryFirst: 0,
    category: ZoneCategories.Home,
  },
  {
    categoryFirst: 3,
    category: ZoneCategories.Office,
  },
  {
    categoryFirst: 6,
    category: ZoneCategories.Commercial,
  },
  {
    categoryFirst: 9,
    category: ZoneCategories.Parking,
  },
];

export const zoneList: ZoneCategoryImages[] = [
  {
    src: "/images/home/gimpo-hankang-new-town.webp",
    text: "김포한강신도시 푸르지오 아파트",
    category: ZoneCategories.Home,
  },
  {
    src: "/images/home/space-bone.webp",
    text: "스페이스본 아파트",
    category: ZoneCategories.Home,
  },
  {
    src: "/images/home/durham-penthouse.webp",
    text: "더라움 펜트하우스",
    category: ZoneCategories.Home,
  },
  {
    src: "/images/commercial/pineAvenue.jpg",
    text: "을지로 파인에비뉴",
    category: ZoneCategories.Office,
  },
  {
    src: "/images/office/lg-daejeon-technical-center.webp",
    text: "LG화학 대전기술원",
    category: ZoneCategories.Office,
  },
  // {
  //   src: "/images/office/yonsei-university.webp",
  //   text: "연세대학교",
  //   category: ZoneCategories.Office,
  // },
  {
    src: "/images/office/nonghyup.webp",
    text: "농협",
    category: ZoneCategories.Office,
  },
  {
    src: "/images/commercial/sangam-DDMC.webp",
    text: "상암 DDMC",
    category: ZoneCategories.Commercial,
  },
  {
    src: "/images/commercial/COEX.webp",
    text: "코엑스",
    category: ZoneCategories.Commercial,
  },
  {
    src: "/images/commercial/kangwon-land.webp",
    text: "강원랜드",
    category: ZoneCategories.Commercial,
  },
  {
    src: "/images/parking/faction-highway-rest-area.webp",
    text: "진영 고속도로 휴게소",
    category: ZoneCategories.Parking,
  },
  {
    src: "/images/parking/namsan-square-public-parking.webp",
    text: "남산스퀘어 공영 주차장",
    category: ZoneCategories.Parking,
  },
  {
    src: "/images/parking/wonju-donghwa-industrial-park.webp",
    text: "원주 동화 산업단지",
    category: ZoneCategories.Parking,
  },
];

export const logoList = [
  "ministry-environment",
  "ministry-trade-industry",
  "seoul",
  "gangwon-office",
  "korea-electric-corporation",
  "anseong-city",
  "yangju-city",
  "SH-conjunction",
  "LH-corporation",
  "jai",
  "lotte-castle",
  "iPark",
  "sk-view",
  "the-shop",
  "easy-world",
  "COEX",
  "sk-networks",
  "samsung-engineering",
  "LG-chemical",
  "LG-Uplus",
  "SK-telecom",
  "SK-rent-car",
  "so-car",
  "KCC",
  "golf-zone",
  "Hyundai-department-store",
  "garden-five",
  "YTN",
  "kookmin-bank",
  "nonghyup",
  "yonsei-university",
  "ewha-university",
  "seoul-national-university",
  "chung-ang-university",
  "chungbuk-national-university",
  "kangwon-land",
  "lamian",
  "hillstate",
  "hoban-summit",
  "NC-department-store",
  "korea-automotive-environmental-association",
  "purgio",
  "doosan-weave",
  "hyundai-motor",
  "hanaro-mart",
  "haiwon-resort",
  "hankuk-university-foreign-studies",
  "walker-hill",
  "sungkyunkwan-university",
];

export interface QnaOrder {
  iconSrc: string;
  title: string;
  description: string;
  buttonText?: string;
  link?: "RecruitingModal" | "downloadFormModal";
}

export const qnaOrder: QnaOrder[] = [
  {
    iconSrc: ICONS.REGISTER,
    title: "1. 신청/문의 접수",
    description: `설치 신청서에 간단한 정보를 입력해주세요.`,
    buttonText: "입력하기",
    link: "RecruitingModal",
  },
  {
    iconSrc: ICONS.COUNSELING,
    title: "2. 상담진행",
    description: `입력하신 연락처로 담당자가 직접 연락드려요.`,
  },
  {
    iconSrc: ICONS.AUDIT,
    title: "3. 현장심사",
    description: `현장방문으로 설치 가능 여부 및 최적의 설치 장소를 제시해요.`,
  },
  {
    iconSrc: ICONS.DOCUMENT,
    title: "4. 보조금 서류 작성",
    description: `환경부 보조금 신청을 위한 필요서류를 준비해요.`,
    buttonText: "설치 신청서 다운로드",
    link: "downloadFormModal",
  },
  {
    iconSrc: ICONS.INSTALLATION,
    title: "5. 충전기 설치",
    description: `예약된 날에 방문하여 충전기 설치를 진행해요.`,
  },
];

export interface DownloadList {
  key: string;
  label: string;
}

export const downloadList: DownloadList[] = [
  {
    key: "환경부-보조금",
    label: "환경부 보조금 설치 신청서",
  },
  {
    key: "환경공단-급속충전시설",
    label: "환경공단 급속충전시설 보조금 설치 신청서",
  },
  {
    key: "서울시-보조금",
    label: "서울시 보조금 설치 신청서",
  },
];

export const appStore =
  "https://apps.apple.com/kr/app/%EC%97%90%EB%B2%84%EC%98%A8-%EC%B6%A9%EC%A0%84%EC%84%9C%EB%B9%84%EC%8A%A4/id1330538811";

export const googlePlay =
  "https://play.google.com/store/apps/details?id=com.chasygo&pli=1";
