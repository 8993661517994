import Text from "@components/atoms/Text";
import { StoryList } from "@constants/home";
import { useCountUp } from "@hooks/useCountUp";

import {
  ANIMATION_COUNT_VALUE,
  colors,
  cssTemplate,
  media,
  typo,
} from "@styles/index";
import React from "react";
import styled from "styled-components";

interface StoryCardProps {
  data: StoryList;
  isVisible?: boolean;
}

const StoryCard: React.FC<StoryCardProps> = ({ data, isVisible }) => {
  const { label, number, title } = data;
  const count = useCountUp(number, 0, ANIMATION_COUNT_VALUE, isVisible);

  return (
    <StyledDiv>
      <Label size={typo.HEADING_04} color={colors.PRIMARY_02}>
        {label}
      </Label>
      <Title size={typo.DISPLAY_04}>
        {isVisible ? count.toLocaleString("ko-KR") : "0"}
        {title}
        {/* <Plus>{" +"}</Plus> */}
      </Title>
    </StyledDiv>
  );
};

export default StoryCard;

const StyledDiv = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  max-width: 310px;
  width: 100%;
  min-width: 250px;

  ${media.TABLET} {
    max-width: 320px;
    min-width: unset;
  }
  ${media.MOBILE} {
    max-width: 151px;
    gap: 4px;
  }
`;

const Title = styled(Text)`
  ${typo.DISPLAY_04};

  ${media.TABLET} {
    ${typo.DISPLAY_05};
  }
  ${media.MOBILE} {
    ${typo.HEADING_01};
  }
`;

const Label = styled(Text)`
  font-weight: 600;
  padding: 8px 0;

  border-bottom: 1.2px solid #6bd3ff;

  ${media.TABLET} {
    ${typo.HEADING_07};
  }

  ${media.MOBILE} {
    padding: unset;
  }
`;

const Plus = styled.span`
  color: ${colors.GRAY_08};
`;
