import { ICONS } from "@assets/index";
import Button, { ButtonProps } from "@components/atoms/Button";
import CommonWrap from "@components/atoms/CommonWrap";
import Icon from "@components/atoms/Icon";
import IconBox from "@components/atoms/IconBox";
import Text from "@components/atoms/Text";
import BaseSection from "@components/template/Layout/BaseSection";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import { AnimationCommonProps } from "@constants/types";
import {
  animations,
  colors,
  media,
  MIN_PC_PADDING_HORIZON,
  PC_PADDING_HORIZON,
  typo,
} from "@styles/index";
import { forwardRef, MutableRefObject } from "react";
import styled, { keyframes } from "styled-components";

interface HomeSection01Props extends AnimationCommonProps {
  ref?: MutableRefObject<any>;
}

const HomeSection01 = forwardRef<any, HomeSection01Props>((props, ref) => {
  const { id } = props;

  const handleDownload = (fileName: string) => {
    const pdfUrl = process.env.PUBLIC_URL + `/pdfs/${fileName}.pdf`;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = `${fileName}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downLoadCompanyProfileData = [
    {
      label: "KOR",
      fileName: "(KOR)_2023_Company Brochure",
    },
    {
      label: "ENG",
      fileName: "(ENG)_2023_Company Brochure",
    },
  ];

  return (
    <StyledBaseSection ref={ref} id={id} fullWidth fullHeight>
      <div className="preload"></div>
      <BannerWrap />

      <ScreenResizing
        pc={
          <StyledCommonWrap width="1296px">
            <ContentsWrap>
              <TitleWrap>
                <Title type="h1">{`마음 편한 충전 파트너\n에버온`}</Title>
                <SubTitle type="h2" color={colors.WHITE} size={typo.BODY_03R}>
                에버온은 시장을 선도하는 서비스와 기술력으로 스마트 충전 플랫폼을 구축하여 <br />
                고객에게 안심 충전 환경을 제공하는 신뢰할 수 있는 파트너입니다.
                </SubTitle>
              </TitleWrap>
              <CompanyProfileContents>
                <StyledText whiteSpace="pre-wrap" minWidth={215}>
                  회사소개서 (Company Profile)
                </StyledText>

                <DownLoadCompanyProfileBox>
                  {downLoadCompanyProfileData.map(
                    ({ label, fileName }, index) => {
                      return (
                        <Button
                          key={index}
                          pcBtnSize="regular"
                          onClick={() => handleDownload(fileName)}
                        >
                          {label}
                          <Icon
                            backgroundSrc={ICONS.DOWNLOAD}
                            width={24}
                            height={24}
                          />
                        </Button>
                      );
                    },
                  )}
                </DownLoadCompanyProfileBox>
              </CompanyProfileContents>
            </ContentsWrap>
          </StyledCommonWrap>
        }
        tablet={
          <StyledCommonWrap width="1296px">
            <ContentsWrap>
              <TitleWrap>
                <Title type="h1">{`마음 편한 충전 파트너\n에버온`}</Title>
                <SubTitle
                  type="h2"
                  whiteSpace="pre-wrap"
                  color={colors.WHITE}
                  size={typo.BODY_04R}
                >
                  {`혁신의 기술력으로 더욱 스마트하고 편리하게\n충전할수 있는 환경을 제공합니다.`}
                </SubTitle>
              </TitleWrap>

              <CompanyProfileContents>
                <StyledText whiteSpace="pre-wrap" minWidth={215}>
                  회사소개서 (Company Profile)
                </StyledText>

                <DownLoadCompanyProfileBox>
                  {downLoadCompanyProfileData.map(
                    ({ label, fileName }, index) => {
                      return (
                        <Button
                          key={index}
                          pcBtnSize="regular"
                          onClick={() => handleDownload(fileName)}
                        >
                          {label}
                          <Icon
                            backgroundSrc={ICONS.DOWNLOAD}
                            width={24}
                            height={24}
                          />
                        </Button>
                      );
                    },
                  )}
                </DownLoadCompanyProfileBox>
              </CompanyProfileContents>
            </ContentsWrap>
          </StyledCommonWrap>
        }
        mobile={
          <StyledCommonWrap width="1296px">
            <ContentsWrap>
              <TitleWrap>
                <Title type="h1">{`마음 편한 충전 파트너\n에버온`}</Title>
                <SubTitle type="h2" color={colors.WHITE} size={typo.BODY_06M}>
                  혁신의 기술력으로 더욱 스마트하고 편리하게 충전할 수 있는
                  환경을 제공합니다.
                </SubTitle>
              </TitleWrap>

              <CompanyProfileContents>
                <StyledText whiteSpace="pre-wrap" minWidth={215}>
                  회사소개서 (Company Profile)
                </StyledText>

                <DownLoadCompanyProfileBox>
                  {downLoadCompanyProfileData.map(
                    ({ label, fileName }, index) => {
                      return (
                        <Button
                          key={index}
                          pcBtnSize="regular"
                          onClick={() => handleDownload(fileName)}
                        >
                          {label}
                          <Icon
                            backgroundSrc={ICONS.DOWNLOAD}
                            width={24}
                            height={24}
                          />
                        </Button>
                      );
                    },
                  )}
                </DownLoadCompanyProfileBox>
              </CompanyProfileContents>
            </ContentsWrap>
          </StyledCommonWrap>
        }
      />
      {/* </BannerWrap> */}

      <StyledIcon backgroundSrc={ICONS.ARROW_DOWN} />
    </StyledBaseSection>
  );
});

HomeSection01.displayName = "HomeSection01";

export default HomeSection01;

const StyledBaseSection = styled(BaseSection)`
  overflow: hidden;

  .preload {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: hidden;
    display: none;
    background: no-repeat center/cover url("/images/section01/banner.webp");
  }
`;

const BannerWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: no-repeat center/cover url("/images/section01/banner.webp");
  height: 100%;

  ${media.TABLET} {
    background-image: url("/images/section01/tablet-banner.webp");
    overflow: hidden;
  }

  ${media.MOBILE} {
    background-image: url("/images/section01/mobile-banner.webp");
  }
`;

const StyledCommonWrap = styled(CommonWrap)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  padding: 0 ${PC_PADDING_HORIZON};

  ${media.MIN_PC} {
    padding: 0 ${MIN_PC_PADDING_HORIZON};
  }

  animation: ${animations.fadeInUp} 1s forwards;
  ${media.TABLET} {
    justify-content: center;
    align-items: flex-start;
    padding: 0 48px;
  }

  ${media.MOBILE} {
    padding: 0 20px;
  }
`;

const ContentsWrap = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 240px;

  ${media.MOBILE} {
    gap: 160px;
  }
`;

const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  ${media.TABLET} {
    max-width: 418px;
  }
  ${media.MOBILE} {
    max-width: 277px;
  }
`;

const Title = styled(Text)`
  color: ${colors.WHITE};
  size: ${typo.DISPLAY_01};
  white-space: pre-line;
  font-weight: 600;

  ${media.TABLET} {
    font-size: 3rem;
    line-height: 68px;
    font-weight: 700;
  }
  ${media.MOBILE} {
    ${typo.DISPLAY_05};
  }
`;

const SubTitle = styled(Text)`
  font-weight: 300;
`;

const CompanyProfileContents = styled.div`
  position: relative;
  height: 72px;
  width: 302px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledText = styled(Text)<{ minWidth?: number }>`
  ${typo.BODY_06M}
  color: ${colors.WHITE};
  font-weight: 400;

  ${({ minWidth }) =>
    minWidth &&
    `
  min-width: ${minWidth}px;
`};
`;

const DownLoadCompanyProfileBox = styled.div`
  display: flex;
  gap: 12px;

  > button {
    padding: 8px 24px;
  }
`;

const DownloadCompanyProfileBtn = styled(Button)<ButtonProps>`
  padding: 16px 24px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  > p {
    ${typo.BODY_06M};
  }
`;

const arrowAnimation = keyframes`
  0% {
    bottom: 20px;
    opacity: 0.3;
  }
  50% {
    bottom: 40px;
    opacity: 1;
  }
  100% {
    bottom: 20px;
    opacity: 0.3;
  }
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  bottom: 40px;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
  animation: ${arrowAnimation} 2s ease-in-out infinite;

  width: 48px;
  height: 48px;

  ${media.MOBILE} {
    width: 38px;
    height: 38px;
  }
`;
