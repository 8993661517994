import { object, string } from "yup";

export const RecruitingSchema = object({
  clientName: string()
    .required("이름을 입력해주세요.")
    .min(2, "최소 2글자 이상 입력해주세요."),
  phoneNumber: string()
    .required("연락처를 입력해주세요.")
    .max(11, "연락처를 전부 입력해주세요."),
  // email: string()
  //   .required("이메일을 입력해주세요.")ß
  //   .email("이메일 형식에 맞게 입력해주세요."),
  address: string()
    .required("주소를 입력해주세요.")
    .min(5, "주소를 전부 입력해주세요."),
  content: string()
    .required("내용을 입력해주세요.")
    .min(5, "내용을 전부 입력해주세요."),
});
