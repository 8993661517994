import CommonWrap from "@components/atoms/CommonWrap";
import ProductCard from "@components/molecule/ProductCard";
import BaseSection from "@components/template/Layout/BaseSection";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import {
  ChargerInfoData,
  chargerInfoDataList,
  productCardList,
  ProductKey,
} from "@constants/home";
import { AnimationCommonProps } from "@constants/types";
import { useIntersecting } from "@hooks/useIntersecting";
import useInterval from "@hooks/useInterval";
import {
  cssTemplate,
  media,
  MIN_PC_PADDING_HORIZON,
  MOBILE_PADDING_HORIZON,
  MOBILE_PADDING_VERTICAL,
  PC_PADDING_HORIZON,
  PC_PADDING_VERTICAL,
  TABLET_PADDING_HORIZON,
  TABLET_PADDING_VERTICAL,
} from "@styles/index";
import { SubTitle, Title } from "@styles/CommonStyled";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ChargerInfoModal from "../ChargerInfoModal";
import TitleWrap from "@components/TitleWrap";

interface HomeSection07Props extends AnimationCommonProps {}

const HomeSection07: React.FC<HomeSection07Props> = ({ id }) => {
  const { ref: sectionRef, isIntersecting: isTitleIntersecting } =
    useIntersecting({
      options: { threshold: 0.3 },
    });
  const { ref: cardWrapRef, isIntersecting: isCardWrapIntersecting } =
    useIntersecting({
      options: { threshold: 0.4 },
    });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTitleAnimation, setIsTitleAnimation] = useState<boolean>(false);
  const [chargerInfoData, setChargerInfoData] = useState<ChargerInfoData>();

  useEffect(() => {
    if (isTitleIntersecting) setIsTitleAnimation(true);
  }, [isTitleIntersecting]);

  const handleProductClick = (productKey: ProductKey) => {
    setIsModalOpen(true);
    const chargerInfo = chargerInfoDataList.filter(
      ({ key }) => productKey === key,
    );

    setChargerInfoData(chargerInfo[0]);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const [isInteracted, setIsInteracted] = useState(-1);
  useInterval(
    () => {
      setIsInteracted(isInteracted + 1);
    },
    isCardWrapIntersecting
      ? isInteracted > productCardList.length
        ? null
        : 150
      : null,
  );
  return (
    <>
      <StyledBaseSection ref={sectionRef} fullWidth height="996px" flexCenter>
        <StyledCommonWrap theme={{ isAnimation: isTitleAnimation }}>
          <StyledTitleWrap id={id}>
            <SubTitle type="h3">Smart Charger</SubTitle>
            <ScreenResizing
              pc={
                <Title type="h2" whiteSpace="pre-line">
                  {`국내 최고 품질의 충전기를 자체 개발하여\n운영하고 있습니다`}
                </Title>
              }
              tablet={
                <Title type="h2" whiteSpace="pre-line">
                  {`국내 최고 품질의 충전기를\n자체 개발하여 운영하고 있습니다`}
                </Title>
              }
              mobile={
                <Title type="h2" whiteSpace="pre-line">
                  {`국내 최고 품질의\n충전기를 자체 개발하여\n운영하고 있습니다`}
                </Title>
              }
            />
          </StyledTitleWrap>

          <CardListWrap ref={cardWrapRef}>
            {productCardList.map((data, index) => {
              return (
                <ProductCard
                  onClick={() => handleProductClick(data.key)}
                  key={data.key}
                  data={data}
                  isAnimation={isInteracted >= index}
                />
              );
            })}
          </CardListWrap>
        </StyledCommonWrap>
      </StyledBaseSection>

      <ChargerInfoModal
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        data={chargerInfoData}
      />
    </>
  );
};

export default HomeSection07;

const StyledBaseSection = styled(BaseSection)`
  padding: ${PC_PADDING_VERTICAL} ${PC_PADDING_HORIZON};

  ${media.MIN_PC} {
    padding: ${PC_PADDING_VERTICAL} ${MIN_PC_PADDING_HORIZON};
  }

  ${media.TABLET} {
    height: auto;
    padding: ${TABLET_PADDING_VERTICAL} ${TABLET_PADDING_HORIZON};
  }
  ${media.MOBILE} {
    height: auto;
    padding: ${MOBILE_PADDING_VERTICAL} ${MOBILE_PADDING_HORIZON};
  }
`;

const StyledCommonWrap = styled(CommonWrap)`
  display: flex;
  flex-direction: column;
  gap: 80px;

  position: relative;

  ${(props) => cssTemplate.customFadeInDown(props)}

  ${media.TABLET} {
    gap: 60px;
  }
  ${media.MOBILE} {
    gap: 40px;
  }
`;

const StyledTitleWrap = styled(TitleWrap)`
  /*  */

  ${media.MOBILE} {
    gap: 8px;
  }
`;

const CardListWrap = styled.div`
  display: flex;
  gap: 40px;
  width: 100%;

  ${media.TABLET} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    row-gap: 60px;
    column-gap: 24px;
  }
  ${media.MOBILE} {
    grid-template-columns: repeat(1, 1fr);
    flex-wrap: wrap;
    padding: 0;
    gap: 24px;
  }
`;
