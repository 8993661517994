import CommonWrap from "@components/atoms/CommonWrap";
import ServiceCard from "@components/molecule/ServiceCard";
import BaseSection from "@components/template/Layout/BaseSection";
import TitleWrap from "@components/TitleWrap";
import { serviceList } from "@constants/home";
import { AnimationCommonProps } from "@constants/types";
import { useIntersecting } from "@hooks/useIntersecting";
import useInterval from "@hooks/useInterval";
import { ContentsWrap, SubTitle, Title } from "@styles/CommonStyled";
import {
  colors,
  cssTemplate,
  maxWidth,
  media,
  MIN_PC_PADDING_HORIZON,
  MOBILE_PADDING_HORIZON,
  MOBILE_PADDING_VERTICAL,
  PC_PADDING_HORIZON,
  PC_PADDING_VERTICAL,
  TABLET_PADDING_HORIZON,
  TABLET_PADDING_VERTICAL,
} from "@styles/index";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface HomeSection03Props extends AnimationCommonProps {}

const HomeSection03: React.FC<HomeSection03Props> = ({ id }) => {
  const { ref: sectionRef, isIntersecting: isTitleIntersecting } =
    useIntersecting({
      options: { threshold: 0.4 },
    });
  const { ref: contentsRef, isIntersecting: isContentsIntersecting } =
    useIntersecting({
      options: { threshold: 0.4 },
    });

  const [isTitleAnimation, setIsTitleAnimation] = useState<boolean>(false);
  const [isContentsAnimation, setIsContentsAnimation] =
    useState<boolean>(false);

  useEffect(() => {
    if (isTitleIntersecting) setIsTitleAnimation(true);
  }, [isTitleIntersecting]);

  useEffect(() => {
    if (isContentsIntersecting) setIsContentsAnimation(true);
  }, [isContentsIntersecting]);

  const [isInteracted, setIsInteracted] = useState(-1);
  useInterval(
    () => {
      setIsInteracted(isInteracted + 1);
    },
    isContentsAnimation
      ? isInteracted > serviceList.length
        ? null
        : 300
      : null,
  );

  return (
    <StyledBaseSection ref={sectionRef} fullWidth height="1192px" flexCenter>
      <StyledCommonWrap>
        <StyledTitleWrap id={id} theme={{ isAnimation: isTitleAnimation }}>
          <SubTitle type="h3">Key Service</SubTitle>
          <Title whiteSpace="pre-line" type="h2">
            {`앞서가는 서비스로\n최고의 고객경험을 제공합니다`}
          </Title>
        </StyledTitleWrap>

        <StyledContentsWrap ref={contentsRef}>
          {serviceList.map((content, index) => {
            return (
              <ServiceCard
                key={content.title}
                data={content}
                isAnimation={isInteracted >= index}
              />
            );
          })}
        </StyledContentsWrap>
      </StyledCommonWrap>
    </StyledBaseSection>
  );
};

export default HomeSection03;

const StyledBaseSection = styled(BaseSection)`
  background: ${colors.GRAY_11};
  padding: ${PC_PADDING_VERTICAL} ${PC_PADDING_HORIZON};

  ${media.MIN_PC} {
    padding: ${PC_PADDING_VERTICAL} ${MIN_PC_PADDING_HORIZON};
  }

  ${media.TABLET} {
    padding: ${TABLET_PADDING_VERTICAL} ${TABLET_PADDING_HORIZON};
    height: 100%;
  }

  ${media.MOBILE} {
    padding: ${MOBILE_PADDING_VERTICAL} ${MOBILE_PADDING_HORIZON};
  }
`;

const StyledCommonWrap = styled(CommonWrap)`
  display: flex;
  gap: 80px;
  flex-direction: column;

  ${maxWidth}
  ${media.TABLET} {
    gap: 60px;
  }
  ${media.MOBILE} {
    gap: 40px;
  }
`;

const StyledTitleWrap = styled(TitleWrap)`
  ${(props) => cssTemplate.customFadeInDown(props)}

  ${media.MOBILE} {
    gap: 8px;
  }
`;

const StyledContentsWrap = styled(ContentsWrap)`
  gap: 32px;

  ${media.TABLET} {
    padding: 0;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
  ${media.MOBILE} {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;
