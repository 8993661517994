import CommonWrap from "@components/atoms/CommonWrap";
import Text from "@components/atoms/Text";
import BaseSection from "@components/template/Layout/BaseSection";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import TitleWrap from "@components/TitleWrap";
import { productCardList } from "@constants/home";
import { useIntersecting } from "@hooks/useIntersecting";
import useInterval from "@hooks/useInterval";
import { SubTitle, Title } from "@styles/CommonStyled";
import {
  colors,
  cssTemplate,
  media,
  MIN_PC_PADDING_HORIZON,
  MOBILE_PADDING_HORIZON,
  MOBILE_PADDING_VERTICAL,
  PC_PADDING_HORIZON,
  PC_PADDING_VERTICAL,
  TABLET_PADDING_HORIZON,
  TABLET_PADDING_VERTICAL,
  typo,
} from "@styles/index";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface Section05Props {
  id?: string;
}

const HomeSection05: React.FC<Section05Props> = ({ id }) => {
  const { ref: sectionRef, isIntersecting: isTitleIntersecting } =
    useIntersecting({
      options: { threshold: 0.3 },
    });
  const { ref: cardWrapRef, isIntersecting: isCardWrapIntersecting } =
    useIntersecting({
      options: { threshold: 0.4 },
    });

  useEffect(() => {
    if (isTitleIntersecting) setIsTitleAnimation(true);
  }, [isTitleIntersecting]);

  const [isTitleAnimation, setIsTitleAnimation] = useState<boolean>(false);

  const [isInteracted, setIsInteracted] = useState(-1);
  useInterval(
    () => {
      setIsInteracted(isInteracted + 1);
    },
    isCardWrapIntersecting
      ? isInteracted > productCardList.length
        ? null
        : 150
      : null,
  );

  const contentsData = [
    {
      label: "사전예방",
      labelColor: colors.PRIMARY_02,
      labelBackgroundColor: colors.PRIMARY_06,
      contents: [
        {
          title: "상시 점검 서비스",
          description: `최상의 충전 환경 유지를 위해 고객의 신고 또는 요청이 없이도 에버온 크루는 전국 충전소를 최소 연 6회 직접 방문하여 상시 점검합니다.`,
          imageUrl: "/images/inspection-services.png",
          keyWord: "에버온 크루",
          keyWordBackgroundColor: colors.PURPLE_05,
          keyWordColor: colors.PURPLE,
        },
        {
          title: "실시간 모니터링 시스템",
          description: `다년간의 기술력으로 에버온의 모든 충전기를 실시간 모니터링 함으로써 최상의 충전 품질을 유지합니다.`,
          imageUrl: "/images/monitor-services.png",
          keyWord: "IT관제",
          keyWordBackgroundColor: colors.GREEN_05,
          keyWordColor: colors.GREEN,
        },
      ],
    },
    {
      label: "사후조치",
      labelColor: colors.WHITE,
      labelBackgroundColor: colors.PRIMARY,
      contents: [
        {
          title: "24시간 이내 전국 출동 서비스",
          description: `고장신고 또는 민원이 접수된 경우, 24시간 이내에 현장 출동하여 대응하며 조치, 점검 과정에 대해 친절한 1:1 알림 서비스를 제공합니다.`,
          imageUrl: "/images/dispatch-services.png",
          keyWord: "에버온 크루",
          keyWordBackgroundColor: colors.PURPLE_05,
          keyWordColor: colors.PURPLE,
        },
        {
          title: "원격 장애 대응 시스템",
          description: `실시간 모니터링으로 확인된 장애를 즉각적으로 대응하고 자동 조치하여 고객 불편을 최소화 합니다.`,
          imageUrl: "/images/failover-services.png",
          keyWord: "IT관제",
          keyWordBackgroundColor: colors.GREEN_05,
          keyWordColor: colors.GREEN,
        },
      ],
    },
  ];

  return (
    <StyledBaseSection ref={sectionRef} fullWidth flexCenter>
      <StyledCommonWrap theme={{ isAnimation: isTitleAnimation }}>
        <StyledTitleWrap id={id}>
          <SubTitle type="h3">everon Care</SubTitle>
          <ScreenResizing
            pc={
              <Title type="h2" whiteSpace="pre-line">
                {`고객이 안심하고 충전할 수 있도록\n최고의 현장대응시스템을 제공합니다`}
              </Title>
            }
            tablet={
              <Title type="h2" whiteSpace="pre-line">
                {`고객이 안심하고 충전할 수 있도록\n최고의 현장대응시스템을 제공합니다`}
              </Title>
            }
            mobile={
              <Title type="h2" whiteSpace="pre-line">
                {`고객이 안심하고\n충전할 수 있도록 최고의\n현장대응시스템을 제공합니다`}
              </Title>
            }
          />
        </StyledTitleWrap>
        <ContentsWrap>
          {contentsData.map(
            ({ label, labelBackgroundColor, labelColor, contents }, idx) => {
              return (
                <CardWrap key={idx}>
                  <CardLabelWrap
                    backgroundColor={labelBackgroundColor}
                    color={labelColor}
                  >
                    <p>{label}</p>
                  </CardLabelWrap>

                  {contents.map(
                    ({
                      title,
                      description,
                      imageUrl,
                      keyWord,
                      keyWordBackgroundColor,
                      keyWordColor,
                    }) => {
                      return (
                        <CardBox key={title}>
                          <CardTitleWrap>
                            <CardTitle>{title}</CardTitle>
                            <CardKeyword
                              backgroundColor={keyWordBackgroundColor}
                              color={keyWordColor}
                            >
                              {keyWord}
                            </CardKeyword>
                          </CardTitleWrap>
                          <CardDisription>{description}</CardDisription>
                          <ImageBox imageUrl={imageUrl} />
                        </CardBox>
                      );
                    },
                  )}
                </CardWrap>
              );
            },
          )}
        </ContentsWrap>
      </StyledCommonWrap>
    </StyledBaseSection>
  );
};

export default HomeSection05;

const StyledBaseSection = styled(BaseSection)`
  background: ${colors.GRAY_11};

  padding: ${PC_PADDING_VERTICAL} ${PC_PADDING_HORIZON};

  ${media.MIN_PC} {
    padding: ${PC_PADDING_VERTICAL} ${MIN_PC_PADDING_HORIZON};
  }

  ${media.TABLET} {
    height: auto;
    padding: 0 ${TABLET_PADDING_HORIZON};
  }
  ${media.MOBILE} {
    height: auto;
    padding: 0 ${MOBILE_PADDING_HORIZON};
  }
`;

const StyledCommonWrap = styled(CommonWrap)`
  display: flex;
  flex-direction: column;
  gap: 80px;

  position: relative;
  /* padding: ${PC_PADDING_VERTICAL} 0; */

  ${(props) => cssTemplate.customFadeInDown(props)}

  ${media.TABLET} {
    gap: 60px;
    padding: ${TABLET_PADDING_VERTICAL} 0;
  }
  ${media.MOBILE} {
    gap: 40px;
    padding: ${MOBILE_PADDING_VERTICAL} 0;
  }
`;

const StyledTitleWrap = styled(TitleWrap)`
  ${media.MOBILE} {
    gap: 8px;
  }
`;

const ContentsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 32px;

  ${media.TABLET} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
`;

const CardBox = styled.div`
  background: ${colors.WHITE};
  padding: 32px;
  width: 100%;
  max-width: 587px;
  height: 416px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.TABLET} {
    width: 100%;
    max-width: unset;
    height: auto;
  }

  ${media.MOBILE} {
    padding: 32px 20px;
  }
`;

const CardTitle = styled(Text)`
  ${typo.HEADING_02};

  ${media.MOBILE} {
    ${typo.HEADING_07};
  }
`;

const CardDisription = styled(Text)`
  ${typo.BODY_07R};
  color: ${colors.GRAY_06};
  white-space: pre-line;
  word-break: keep-all;
  margin-bottom: 24px;

  ${media.TABLET} {
    max-width: 500px;
  }

  ${media.MOBILE} {
    ${typo.BODY_09R};
    margin-bottom: 20px;
  }
`;

const ImageBox = styled.span<{ imageUrl: string }>`
  display: inline-block;
  max-width: 523px;
  width: 100%;
  height: 226px;
  border-radius: 12px;
  background: no-repeat center/cover url(${({ imageUrl }) => imageUrl});

  ${media.TABLET} {
    max-width: 100%;
    width: 100%;
    height: 292px;
  }

  ${media.MOBILE} {
    height: 127px;
  }
`;

const CardWrap = styled.div`
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

  ${CardBox} + ${CardBox} {
    :before {
      content: "";
      display: block;
      width: 87%;
      height: 1px;
      background-color: ${colors.GRAY_09};
      position: absolute;
      top: 487px;
      left: 50%;
      transform: translateX(-50%);
    }

    ${media.TABLET} {
      :before {
        height: 1px;
        width: 91%;
        top: 555px;
      }
    }

    ${media.MOBILE} {
      :before {
        top: 377px;
      }
    }
  }
`;

const CardLabelWrap = styled.div<{ backgroundColor: string; color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  ${typo.BODY_04M};
`;

const CardTitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 8px;
`;

const CardKeyword = styled.span<{ backgroundColor: string; color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 3px 6px;
  border-radius: 6px;
  ${typo.BODY_10M}

  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
`;
