import useGlobalModal from "@hooks/useGlobalModal";
import useRouter from "@hooks/useRouter";
import { MenuItem } from "@pages/Home";
import { colors, cssTemplate, maxWidth, typo, zIndex } from "@styles/index";
import React, { useState } from "react";
import { Link as ScrollLink, scroller } from "react-scroll";
import styled, { css } from "styled-components";

interface MenuProps {
  headerWhite?: boolean;
  activeMenu?: MenuItem;
}

const Menu: React.FC<MenuProps> = ({ headerWhite, activeMenu }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { pathname, push } = useRouter();
  const { showAlert } = useGlobalModal();

  const menuList = [
    { label: "회사소개", id: "compony" },
    { label: "핵심서비스", id: "service" },
    { label: "대표사례", id: "produce" },
    { label: "설치문의", id: "install" },
  ];

  const handleMouseEnter = () => setIsHovered(true);

  const handleMouseLeave = () => setIsHovered(false);

  const handleScroll = (id: string) => {
    if (pathname !== "/") {
      push("/");
      setTimeout(() => {
        scroller.scrollTo(id, {
          duration: 1000,
          offset: -100,
          smooth: "easeInOut",
        });
      }, 200);
    }
  };

  return (
    <StyledWrap>
      <StyledList activeMenu={activeMenu} headerWhite={headerWhite}>
        {menuList.map(({ label, id }) => {
          return (
            <li key={id}>
              <StyledLink
                onClick={() => handleScroll(id)}
                to={id}
                offset={-100}
                spy
                smooth
                duration={1000}
                headerwhite={headerWhite?.toString()}
              >
                <StyledMenu headerWhite={headerWhite}>{label}</StyledMenu>
              </StyledLink>
            </li>
          );
        })}
      </StyledList>

      <ButtonWrap
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <StyledDiv2>
          <span
            onClick={() => {
              window.open(
                "https://account.everon.co.kr/chasygo/CorpAdmin/login",
              );
            }}
          >
            법인 로그인
          </span>
          <span>|</span>
          <span
            onClick={() => {
              if (pathname.includes("/corporate/signup")) return;
              push("/corporate/signup");
            }}
          >
            법인 회원가입
          </span>
        </StyledDiv2>
        <StyledDiv isHovered={isHovered} headerWhite={headerWhite}>
          법인관리자
        </StyledDiv>
      </ButtonWrap>
    </StyledWrap>
  );
};

export default Menu;

const StyledWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 5%;
`;

const StyledMenu = styled.p<{ headerWhite?: boolean }>`
  color: ${({ headerWhite }) => (headerWhite ? colors.GRAY_02 : colors.WHITE)};
  font-weight: ${({ headerWhite }) => (headerWhite ? 600 : 500)};
  font-size: 1.125rem;
  transition: 0.3s;
`;

/**
 * // TODO
 * - scroll시 메뉴 active 되게 구현
 */
const StyledLink = styled(ScrollLink)<{
  headerwhite?: string;
}>`
  position: relative;
  padding: 10px 0;

  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0%;
    height: 2px;
    background: ${colors.WHITE};
    opacity: 0;
    transition: 0.5s;
  }

  :hover {
    ${({ headerwhite }) => {
      if (headerwhite === "false") {
        return css`
          ::after {
            background: ${colors.WHITE};
            width: 100%;
            left: 0;
            opacity: 1;
          }
        `;
      }
    }}

    ${StyledMenu} {
      ${({ headerwhite }) => {
        if (headerwhite === "true") {
          return css`
            color: ${colors.PRIMARY};
          `;
        } else {
          return css`
            color: ${colors.WHITE};
          `;
        }
      }}
    }
  }
`;

const StyledList = styled.ul<{ activeMenu?: MenuItem; headerWhite?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  gap: 48px;
  margin-right: 150px;

  text-align: center;

  width: 100%;
  ${maxWidth}

  li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 44px;
    min-width: fit-content;
    cursor: pointer;
  }

  /* MENU ACTIVE ANIMATION */
  ${({ activeMenu, headerWhite }) => {
    if (activeMenu === "compony") {
      if (!headerWhite) {
        return css`
          li:nth-child(1) {
            > ${StyledLink} {
              ::after {
                opacity: 1;
                width: 100%;
                left: 0;
                background: ${colors.WHITE};
              }
            }
          }
        `;
      } else {
        return css`
          li:nth-child(1) {
            > ${StyledLink} {
              ::after {
                opacity: 1;
                width: 100%;
                left: 0;
                background: ${colors.PRIMARY};
              }

              ${StyledMenu} {
                color: ${colors.PRIMARY};
              }
            }
          }
        `;
      }
    }
    if (activeMenu === "service") {
      if (!headerWhite) {
        return css`
          li:nth-child(2) {
            > ${StyledLink} {
              ::after {
                opacity: 1;
                background: ${colors.WHITE};
              }
            }
          }
        `;
      } else {
        return css`
          li:nth-child(2) {
            > ${StyledLink} {
              ::after {
                opacity: 1;
                width: 100%;
                left: 0;
                background: ${colors.PRIMARY};
              }

              ${StyledMenu} {
                color: ${colors.PRIMARY};
              }
            }
          }
        `;
      }
    }
    if (activeMenu === "produce") {
      if (!headerWhite) {
        return css`
          li:nth-child(3) {
            > ${StyledLink} {
              ::after {
                opacity: 1;
                background: ${colors.WHITE};
              }
            }
          }
        `;
      } else {
        return css`
          li:nth-child(3) {
            > ${StyledLink} {
              ::after {
                opacity: 1;
                width: 100%;
                left: 0;
                background: ${colors.PRIMARY};
              }

              ${StyledMenu} {
                color: ${colors.PRIMARY};
              }
            }
          }
        `;
      }
    }
    if (activeMenu === "install") {
      if (!headerWhite) {
        return css`
          li:nth-child(4) {
            > ${StyledLink} {
              ::after {
                opacity: 1;
                background: ${colors.WHITE};
              }
            }
          }
        `;
      } else {
        return css`
          li:nth-child(4) {
            > ${StyledLink} {
              ::after {
                opacity: 1;
                width: 100%;
                left: 0;
                background: ${colors.PRIMARY};
              }

              ${StyledMenu} {
                color: ${colors.PRIMARY};
              }
            }
          }
        `;
      }
    }
  }}
`;

const StyledDiv = styled.div<{ isHovered: boolean; headerWhite?: boolean }>`
  background-color: ${colors.GRAY_06};
  color: ${colors.WHITE};
  padding: 12px 24px 10px;
  border-radius: 12px;

  ${typo.BODY_07M}
  line-height: 20px;
  font-weight: 500;
  cursor: pointer;

  min-width: 225px;
  height: 44px;

  display: flex;
  justify-content: center;

  position: absolute;
  z-index: 63;

  ${({ isHovered }) =>
    isHovered
      ? css`
          opacity: 0;
          z-index: ${zIndex.HEADER};
        `
      : css`
          opacity: 1;
          z-index: ${zIndex.HEADER_OUT_BUTTON};
        `}

  ${cssTemplate.commonTrasition};
`;
const StyledDiv3 = styled.div<{ otherIsHovered: boolean; headerWhite?: boolean }>`
  background-color: ${colors.GRAY_06};
  color: ${colors.WHITE};
  padding: 12px 24px 10px;
  border-radius: 12px;

  ${typo.BODY_07M}
  line-height: 20px;
  font-weight: 500;
  cursor: pointer;

  min-width: 225px;
  height: 44px;

  display: flex;
  justify-content: center;

  position: absolute;
  z-index: 63;

  ${({ otherIsHovered }) =>
  otherIsHovered
      ? css`
          opacity: 0;
          z-index: ${zIndex.HEADER};
        `
      : css`
          opacity: 1;
          z-index: ${zIndex.HEADER_OUT_BUTTON};
        `}

  ${cssTemplate.commonTrasition};
`;

const StyledDiv2 = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  min-width: 225px;
  height: 43.5px;

  color: ${colors.WHITE};
  background: ${colors.GRAY_06};
  border-radius: 12px;
  padding: 2px 12px 0;
  z-index: ${zIndex.HEADER_INNER_BUTTON};
  cursor: pointer;

  > span {
    cursor: pointer;
  }

  & :nth-child(2) {
    color: ${colors.GRAY_08};
    cursor: default;
  }
`;

const ButtonWrap = styled.div`
  position: relative;
  width: 225px;
  min-width: 225px;
  height: 44px;
  display: flex;
  justify-content: flex-end;
  z-index: ${zIndex.HEADER_BUTTON_WRAP};
`;
