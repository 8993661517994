import CommonWrap from "@components/atoms/CommonWrap";
import StoryCard from "@components/molecule/StoryCard";
import BaseSection from "@components/template/Layout/BaseSection";
import { storyList } from "@constants/home";
import { AnimationCommonProps } from "@constants/types";
import { useIntersecting } from "@hooks/useIntersecting";
import {
  animations,
  colors,
  maxWidth,
  media,
  MIN_PC_PADDING_HORIZON,
  MOBILE_PADDING_HORIZON,
  MOBILE_PADDING_VERTICAL,
  PC_PADDING_HORIZON,
  PC_PADDING_VERTICAL,
  TABLET_PADDING_HORIZON,
  TABLET_PADDING_VERTICAL,
} from "@styles/index";
import { ContentsWrap, SubTitle, Title } from "@styles/CommonStyled";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import TitleWrap from "@components/TitleWrap";

interface HomeSection02Props extends AnimationCommonProps {}

const HomeSection02: React.FC<HomeSection02Props> = ({ id }) => {
  const { ref: titleWrapRef, isIntersecting: titleWrapIsIntersecting } =
    useIntersecting({
      options: { threshold: 0.5 },
    });

  const [isTitleWrapAnimation, setIsTitleWrapAnimation] =
    useState<boolean>(false);

  useEffect(() => {
    if (titleWrapIsIntersecting) setIsTitleWrapAnimation(true);
  }, [titleWrapIsIntersecting]);

  const renderStoryCards = () =>
    storyList.map((content) => (
      <StoryCard
        key={content.label}
        data={content}
        isVisible={isTitleWrapAnimation}
      />
    ));

  return (
    <StyledBaseSection id={id} fullWidth height="638px">
      <StyledCommonWrap isAnimationActive={isTitleWrapAnimation}>
        <AnimationTitleWrap>
          <StyledSubTitle type="h3">Company Index</StyledSubTitle>
          <StyledTitle whiteSpace="pre-line" type="h2">
            에버온은 빠르게
            <br />
            <span className="point-text">성장</span>
            하고 있습니다
          </StyledTitle>
        </AnimationTitleWrap>
        <StyledContentsWrap ref={titleWrapRef}>
          {renderStoryCards()}
        </StyledContentsWrap>
      </StyledCommonWrap>
    </StyledBaseSection>
  );
};

export default HomeSection02;

const StyledBaseSection = styled(BaseSection)`
  overflow: hidden;
  padding: ${PC_PADDING_VERTICAL} ${PC_PADDING_HORIZON};
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.MIN_PC} {
    padding: ${PC_PADDING_VERTICAL} ${MIN_PC_PADDING_HORIZON};
  }

  ${media.TABLET} {
    height: 676px;
    padding: ${TABLET_PADDING_VERTICAL} ${TABLET_PADDING_HORIZON};
  }

  ${media.MOBILE} {
    height: 476px;
    padding: ${MOBILE_PADDING_VERTICAL} ${MOBILE_PADDING_HORIZON};
  }
`;

const StyledCommonWrap = styled(CommonWrap)<{ isAnimationActive?: boolean }>`
  ${({ isAnimationActive }) =>
    isAnimationActive &&
    css`
      animation: ${animations.onlySection02PcTextFadeIn} 1s forwards;
      ${media.TABLET} {
        animation: ${animations.onlySection02TabletTextFadeIn} 1s forwards;
      }
    `}

  ${maxWidth}
  display: flex;
  gap: 10%;
  opacity: 0;

  ${media.TABLET} {
    flex-direction: column;
    gap: 60px;
    width: 100%;
    transform: unset;
  }

  ${media.MOBILE} {
    gap: 40px;
  }
`;

const AnimationTitleWrap = styled(TitleWrap)`
  min-width: 358px;

  ${media.MOBILE} {
    min-width: unset;
    width: 100%;
    gap: 8px;
  }
`;

const StyledSubTitle = styled(SubTitle)`
  width: fit-content;
`;

const StyledTitle = styled(Title)`
  width: 100%;

  .point-text {
    color: ${colors.PRIMARY_02};
  }
`;

const StyledContentsWrap = styled(ContentsWrap)`
  width: 100%;
  gap: 60px 80px;
  grid-template-columns: repeat(2, 1fr);

  ${media.TABLET} {
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
  }
`;
