import Text from "@components/atoms/Text";
import BaseLayoutModal from "@components/template/BaseLayoutModal";
import { CommonModalProps } from "@constants/types";
import { colors, media, typo } from "@styles/index";
import React from "react";
import styled from "styled-components";

interface TermsModalProps extends CommonModalProps {}

const TermsModal: React.FC<TermsModalProps> = ({
  isModalOpen,
  handleModalClose,
}) => {
  return (
    <StyledBaseLayoutModal
      isOpen={isModalOpen}
      handleModalClose={handleModalClose}
      unActiveScrollLock
    >
      <Wrap>
        <Text size={typo.HEADING_01}>개인정보 수집 및 이용 안내</Text>
        <Text
          whiteSpace="pre-line"
          wordBreak="keep-all"
          size={typo.BODY_09R}
          color={colors.GRAY_04}
        >
          {`개인정보 수집 및 이용에 대한 안내
        
        (주)에버온 충전기 설치 문의 및 상담을 위한 이용 동의이며, 이에 동의한 경우에 한하여 설치 문의 접수 및 상담을 진행할 수 있습니다. 
        
        1. 수집 항목신청자명, 연락처, 이메일, 주소\n2. 수집 목적충전기 설치 신청 접수 및 상담\n3. 이용 기간원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 
        
        단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 아래와 같이 관계법령에서 정한 일정 기간동안 개인정보를 보관할 수 있습니다.- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)위의 개인정보 수집 및 이용에 동의합니다.`}
        </Text>
      </Wrap>
    </StyledBaseLayoutModal>
  );
};

export default TermsModal;

const StyledBaseLayoutModal = styled(BaseLayoutModal)`
  width: 560px;
  padding: 32px 40px 24px;
  height: auto;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 4px 32px rgba(53, 58, 63, 0.1);
  overflow: hidden;

  ${media.MOBILE} {
    width: 100%;
    height: 100%;
    border-radius: unset;
    padding: 23px 20px 24px;
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  gap: 20px;
`;
