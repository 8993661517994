import EVERON_LOGO_WHITE from "./icons/everon-logo-white.svg";
import EVERON_LOGO_BLACK from "./icons/everon-logo-black.svg";
import EVERON_LOGO from "./icons/everon-logo.svg";
import PAYMENT from "./icons/payment.svg";
import CALL from "./icons/call.svg";
import CHARGING from "./icons/charging.svg";
import COMPENSATION from "./icons/compensation.svg";
import CREW from "./icons/crew.svg";
import SYSTEM from "./icons/system.svg";
import APPLE from "./icons/apple.svg";
import ANDROID from "./icons/android.svg";
import OCPP from "./icons/ocpp.svg";
import LOAD from "./icons/load.svg";
import ESS from "./icons/ess.svg";
import DATA from "./icons/data.svg";
import DR from "./icons/dr.svg";
import PNC from "./icons/pnc.svg";
import WIFI from "./icons/wifi.svg";
import V2G from "./icons/v2g.svg";
import ENERGY from "./icons/energy.svg";
import BATTERY from "./icons/battery.svg";
import SEARCH from "./icons/search.svg";
import AUDIT from "./icons/audit.svg";
import COUNSELING from "./icons/counseling.svg";
import DOCUMENT from "./icons/document.svg";
import INSTALLATION from "./icons/installation.svg";
import REGISTER from "./icons/register.svg";
import COLORWAY_ARROW_DOWN from "./icons/colorway-arrow-down.svg";
import ARROW_DOWN_GRAY from "./icons/arrow-down-gray.svg";
import ARROW_RIGHT_GRAY from "./icons/arrow-right-gray.svg";
import ARROW_DOWN from "./icons/arrow-down.svg";
import ARROW_LEFT from "./icons/arrow-left.svg";
import ARROW_RIGHT from "./icons/arrow-right.svg";
import FILL_ARROW_DOWN from "./icons/fill-arrow-down.svg";
import CLOSE from "./icons/close.svg";
import CHECK from "./icons/check.svg";
import HAMBURGER_BAR_WHITE from "./icons/hamburger-bar-white.svg";
import HAMBURGER_BAR_BLACK from "./icons/hamburger-bar-black.svg";
import WARNING_CIRCLE from "./icons/warning-circle.svg";
import FILL_ARROW from "./icons/fill-arrow-up.svg";
import DOWNLOAD from "./icons/download.svg";

export const ICONS = {
  // common
  EVERON_LOGO_WHITE,
  EVERON_LOGO_BLACK,
  EVERON_LOGO,
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  ARROW_DOWN_GRAY,
  ARROW_RIGHT_GRAY,
  COLORWAY_ARROW_DOWN,
  CLOSE,
  CHECK,
  FILL_ARROW_DOWN,
  WARNING_CIRCLE,
  FILL_ARROW,
  DOWNLOAD,

  // header
  HAMBURGER_BAR_WHITE,
  HAMBURGER_BAR_BLACK,

  // section03
  PAYMENT,
  SYSTEM,
  CREW,
  COMPENSATION,
  CHARGING,
  CALL,

  // section04
  APPLE,
  ANDROID,

  // section06
  OCPP,
  LOAD,
  ESS,
  DATA,
  DR,
  PNC,
  WIFI,
  V2G,
  ENERGY,
  SEARCH,
  BATTERY,

  // section10
  AUDIT,
  COUNSELING,
  DOCUMENT,
  INSTALLATION,
  REGISTER,
};
