import { requestFaq } from "@apis/index";
import { ICONS } from "@assets/index";
import Button from "@components/atoms/Button";
import Icon from "@components/atoms/Icon";
import IconBox from "@components/atoms/IconBox";
import Text from "@components/atoms/Text";
import RecruitingInputSection from "@components/molecule/RecruitingInputSection";
import RecruitingTipSection from "@components/molecule/RecruitingTipSection";
import BaseLayoutModal from "@components/template/BaseLayoutModal";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import { RecruitingSchema } from "@constants/regex";
import { CommonModalProps } from "@constants/types";
import useGlobalModal from "@hooks/useGlobalModal";
import {
  ColumnWrap,
  ModalDescription,
  ModalTitle,
  ModalTitleWrap,
} from "@styles/CommonStyled";
import { colors, media, MOBILE_PADDING_HORIZON, typo } from "@styles/index";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import TermsModal from "../TermsModal";

type RecruitingModalProps = CommonModalProps;

export interface RecrutingType {
  clientName: string;
  phoneNumber: string;
  email: string;
  address: string;
  content: string;
}

const RecruitingModal: React.FC<RecruitingModalProps> = ({
  isModalOpen,
  handleModalClose,
}) => {
  const { showAlert } = useGlobalModal();
  const [isTermsModal, setIsTermsModal] = useState(false);

  const [isTermsAgree, setIsTermsAgree] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);

  const { mutate } = useMutation(requestFaq, {
    onSuccess: (e) => {
      showAlert({});
    },
    onError: (error) => {
      showAlert({
        title: "오류가 발생했습니다.",
        message: `잠시 후 다시 시도해주세요.\n${error}`,
        buttonText: "확인",
      });
    },
  });

  const openTermsModal = () => {
    setIsTermsModal(true);
  };

  const closeTermsModal = () => {
    setIsTermsModal(false);
  };

  const formik = useFormik<RecrutingType>({
    initialValues: {
      clientName: "",
      phoneNumber: "",
      email: "",
      address: "",
      content: "",
    },
    validationSchema: RecruitingSchema,
    onSubmit: (values) => {},
  });

  const handleSubmit = () => {
    handleModalClose();
    formik.resetForm();
    setIsTermsAgree(false);

    const { clientName, phoneNumber, email, address, content } = formik.values;

    const body = {
      clientName,
      phoneNumber,
      email,
      address,
      content,
    };

    mutate(body);
  };

  useEffect(() => {
    if (formik.dirty && formik.isValid && isTermsAgree) {
      setIsFormComplete(true);
    } else {
      setIsFormComplete(false);
    }
  }, [formik.dirty, formik.isValid, isTermsAgree]);

  return (
    <BaseLayoutModal
      isOpen={isModalOpen}
      handleModalClose={handleModalClose}
      modalPaddingDisabled
      innerWrapStyle={{ overflowY: "auto" }}
    >
      <ScreenResizing
        pc={
          <StyledModalWrap>
            <ModalTitleWrap>
              <ModalTitle>
                충전기 설치 신청 또는 문의사항을 보내주세요.
              </ModalTitle>
              <ModalDescription>
                설치를 제외한 고장신고나 제휴관련 문의는 콜센터 또는
                어플리케이션 1:1 문의를 이용해주세요.
              </ModalDescription>
            </ModalTitleWrap>
            <ContentsWrap>
              <RecruitingInputSection
                isFormComplete={isFormComplete}
                isTermsAgree={isTermsAgree}
                setIsTermsAgree={setIsTermsAgree}
                onChange={formik.handleChange}
                handleModalClose={handleModalClose}
                formReset={formik.resetForm}
                onSubmit={handleSubmit}
                values={formik.values}
              />
              <RecruitingTipSection />
            </ContentsWrap>
          </StyledModalWrap>
        }
        tablet={
          <>
            <ModalInputWrap>
              <ModalTitleWrap>
                <ModalTitle>
                  충전기 설치 신청 또는 문의사항을 보내주세요.
                </ModalTitle>
                <ModalDescription>
                  설치를 제외한 고장신고나 제휴관련 문의는 콜센터 또는
                  어플리케이션 1:1 문의를 이용해주세요.
                </ModalDescription>
              </ModalTitleWrap>
              <RecruitingInputSection
                isFormComplete={isFormComplete}
                isTermsAgree={isTermsAgree}
                setIsTermsAgree={setIsTermsAgree}
                onChange={formik.handleChange}
                handleModalClose={handleModalClose}
                formReset={formik.resetForm}
                values={formik.values}
              />
            </ModalInputWrap>
            <ContentsWrap>
              <RecruitingTipSection />
            </ContentsWrap>
          </>
        }
        mobile={
          <>
            <ModalInputWrap>
              <ModalTitleWrap>
                <ModalTitle whiteSpace="pre-line">
                  {`충전기 설치 신청 또는\n 문의사항을 보내주세요.`}
                </ModalTitle>
                <ModalDescription>
                  설치를 제외한 고장신고나 제휴관련 문의는 콜센터 또는
                  어플리케이션 1:1 문의를 이용해주세요.
                </ModalDescription>
              </ModalTitleWrap>
              <RecruitingInputSection
                isFormComplete={isFormComplete}
                isTermsAgree={isTermsAgree}
                setIsTermsAgree={setIsTermsAgree}
                onChange={formik.handleChange}
                handleModalClose={handleModalClose}
                formReset={formik.resetForm}
                values={formik.values}
              />
            </ModalInputWrap>
            <ContentsWrap>
              <RecruitingTipSection />
            </ContentsWrap>
          </>
        }
      />

      {/* TABLET과 MOBILE의 약관 동의및 제출 버튼  */}
      <ScreenResizing
        tablet={
          <>
            <Gradetion />
            <StyledColumnWrap transFlexDirection="row">
              <TermsTextWrap
                onClick={() => {
                  setIsTermsAgree((prev: boolean) => {
                    return !prev;
                  });
                }}
              >
                <IconBox
                  src={ICONS.CHECK}
                  iconWidth={16}
                  iconHeight={16}
                  width={24}
                  height={24}
                  radius={8}
                  boxBgColor={isTermsAgree ? colors.PRIMARY : colors.GRAY_08}
                  isCursor
                />
                <StyledText size={typo.BODY_06R}>
                  <span>[필수]</span> 개인정보 수집 및 이용 안내에 대한 동의
                </StyledText>
              </TermsTextWrap>

              <TermsDetailButton onClick={openTermsModal}>
                <Icon
                  backgroundSrc={ICONS.ARROW_RIGHT_GRAY}
                  width={16}
                  height={16}
                />
              </TermsDetailButton>
            </StyledColumnWrap>

            <SubmitButtonWrap>
              <StyledButton
                pcBtnSize="extra"
                disabled={!isFormComplete}
                onClick={handleSubmit}
              >
                제출하기
              </StyledButton>
            </SubmitButtonWrap>
          </>
        }
        mobile={
          <>
            <Gradetion />
            <StyledColumnWrap transFlexDirection="row">
              <TermsTextWrap
                onClick={() => {
                  setIsTermsAgree((prev: boolean) => {
                    return !prev;
                  });
                }}
              >
                <IconBox
                  src={ICONS.CHECK}
                  iconWidth={16}
                  iconHeight={16}
                  width={24}
                  height={24}
                  radius={8}
                  boxBgColor={isTermsAgree ? colors.PRIMARY : colors.GRAY_08}
                  isCursor
                />
                <StyledText size={typo.BODY_06R}>
                  <span>[필수]</span> 개인정보취급방침 동의
                </StyledText>
              </TermsTextWrap>

              <TermsDetailButton onClick={openTermsModal}>
                <Icon
                  backgroundSrc={ICONS.ARROW_RIGHT_GRAY}
                  width={16}
                  height={16}
                />
              </TermsDetailButton>
            </StyledColumnWrap>

            <SubmitButtonWrap>
              <StyledButton
                pcBtnSize="extra"
                disabled={!isFormComplete}
                onClick={handleSubmit}
              >
                제출하기
              </StyledButton>
            </SubmitButtonWrap>
          </>
        }
      />

      <TermsModal
        isModalOpen={isTermsModal}
        handleModalClose={closeTermsModal}
      />
    </BaseLayoutModal>
  );
};

export default RecruitingModal;

const ContentsWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 40px;

  ${media.TABLET} {
    width: 100%;
    background-color: gray;
    margin-top: unset;
  }
`;

const StyledModalWrap = styled.div`
  padding: 40px 100px 100px;

  ${media.TABLET} {
    padding: unset;
  }
`;

const ModalInputWrap = styled.div`
  ${media.TABLET} {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 0px 40px 40px;
  }

  ${media.MOBILE} {
    padding: 0px ${MOBILE_PADDING_HORIZON} 40px;
    gap: 20px;
  }
`;

const StyledText = styled(Text)`
  width: 100%;
  > span {
    ${typo.BODY_06M}
  }
  ${media.MOBILE} {
    min-width: 200px;
  }
`;

const TermsTextWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

const StyledColumnWrap = styled(ColumnWrap)`
  ${media.TABLET} {
    padding: 24px 50px;
    justify-content: space-between;
  }
  ${media.MOBILE} {
    padding: 20px 30px;
    justify-content: space-between;
  }
`;

const SubmitButtonWrap = styled.div`
  padding: 0 40px 50px;
  ${media.MOBILE} {
    padding: 0 20px 50px;
  }
`;
const StyledButton = styled(Button)`
  width: 100%;
`;

const Gradetion = styled.div`
  background: linear-gradient(to bottom, #f7f9fa 0%, #ffffff 100%);
  height: 20px;
`;

const TermsDetailButton = styled.div``;
