import { ICONS } from "@assets/index";
import Icon from "@components/atoms/Icon";
import Menu from "@components/molecule/Menu";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import { appStore, googlePlay } from "@constants/home";
import useGlobalModal from "@hooks/useGlobalModal";
import useRouter from "@hooks/useRouter";
import { MenuItem } from "@pages/Home";
import {
  colors,
  cssTemplate,
  maxWidth,
  media,
  MIN_PC_PADDING_HORIZON,
  MOBILE_PADDING_HORIZON,
  PC_PADDING_HORIZON,
  TABLET_PADDING_HORIZON,
  typo,
  zIndex,
} from "@styles/index";

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useAnimate } from "react-simple-animate";
import styled, { css } from "styled-components";

interface HeaderProps {
  headerWhite?: boolean;
  activeMenu?: MenuItem;
}

const Header: React.FC<HeaderProps> = ({ headerWhite, activeMenu }) => {
  const { push, pathname } = useRouter();
  const headerRef = useRef<HTMLDivElement>(null);
  const { play, style } = useAnimate({
    start: { height: 0, opacity: 0 },
    end: { height: "auto", opacity: 1 },
    duration: 0.2,
  });
  const [isMenuBarOpen, setIsMenuBarOpen] = useState(false);
  const [isHeaderBgChange, setIsHeaderBgChange] = useState<boolean>(
    headerWhite ?? false,
  );

  const scrollEventHandler = () => {
    const currentScrollY = window.pageYOffset;
    const headerBgChangeScroll = window.innerHeight * 0.2;

    if (currentScrollY > headerBgChangeScroll) {
      setIsHeaderBgChange(true);
    } else {
      setIsHeaderBgChange(false);
    }
    setIsMenuBarOpen(false);
    play(false);
  };

  const menuOpenHandler = () => {
    if (isMenuBarOpen) {
      play(false);
      setIsMenuBarOpen(false);
    } else {
      play(true);
      setIsMenuBarOpen(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollEventHandler);
    return () => window.removeEventListener("scroll", scrollEventHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sideMenuList = [
    {
      label: "법인 로그인",
      link: "https://account.everon.co.kr/chasygo/CorpAdmin/login",
    },
    {
      label: "법인 회원가입",
      link: "/corporate/signup",
    },
  ];

  const onMovePage = ({ label, link }: { label: string; link: string }) => {
    menuOpenHandler();
    if (label === "법인 로그인") {
      window.open(link, "_blank");
    } else {
      if (pathname.includes("/corporate/signup")) return;
      push(link);
    }
  };

  const homePageLink = process.env.REACT_APP_HOME_URL;

  const redirectStore = () => {
    const userAgent = navigator.userAgent;

    let url = "";

    // Android
    if (/android/i.test(userAgent)) {
      url = googlePlay;
    }

    // iOS
    if (/iPad|iPhone|iPod|mac/i.test(userAgent)) {
      url = appStore;
    }

    return url;
  };

  return (
    <HeaderWrap>
      <Wrapper
        ref={headerRef}
        headerWhite={headerWhite || isHeaderBgChange || isMenuBarOpen}
      >
        {/* LOGO */}
        <StyledNav>
          <Link to={"/"}>
            <ScreenResizing
              pc={
                <Icon
                  backgroundSrc={
                    headerWhite || isHeaderBgChange || isMenuBarOpen
                      ? ICONS.EVERON_LOGO
                      : ICONS.EVERON_LOGO_WHITE
                  }
                  width={130}
                  height={28}
                />
              }
              tablet={
                <Icon
                  backgroundSrc={
                    headerWhite || isHeaderBgChange || isMenuBarOpen
                      ? ICONS.EVERON_LOGO
                      : ICONS.EVERON_LOGO_WHITE
                  }
                  width={130}
                  height={28}
                />
              }
              mobile={
                <Icon
                  backgroundSrc={
                    headerWhite || isHeaderBgChange || isMenuBarOpen
                      ? ICONS.EVERON_LOGO
                      : ICONS.EVERON_LOGO_WHITE
                  }
                  width={97}
                  height={21}
                />
              }
            />
          </Link>

          {/* MENU */}
          <ScreenResizing
            pc={
              <Menu
                headerWhite={headerWhite || isHeaderBgChange || isMenuBarOpen}
                activeMenu={activeMenu}
              />
            }
            tablet={
              <>
                {isMenuBarOpen ? (
                  <Icon
                    backgroundSrc={ICONS.CLOSE}
                    width={40}
                    height={40}
                    onClick={menuOpenHandler}
                  />
                ) : (
                  <Icon
                    backgroundSrc={
                      headerWhite || isHeaderBgChange || isMenuBarOpen
                        ? ICONS.HAMBURGER_BAR_BLACK
                        : ICONS.HAMBURGER_BAR_WHITE
                    }
                    width={40}
                    height={40}
                    onClick={menuOpenHandler}
                  />
                )}
              </>
            }
            mobile={
              <>
                {isMenuBarOpen ? (
                  <Icon
                    backgroundSrc={ICONS.CLOSE}
                    width={28}
                    height={28}
                    onClick={menuOpenHandler}
                  />
                ) : (
                  <Icon
                    backgroundSrc={
                      headerWhite || isHeaderBgChange || isMenuBarOpen
                        ? ICONS.HAMBURGER_BAR_BLACK
                        : ICONS.HAMBURGER_BAR_WHITE
                    }
                    width={28}
                    height={28}
                    onClick={menuOpenHandler}
                  />
                )}
              </>
            }
          />
        </StyledNav>

        <SubLinkHideContents>
          <a href={`${homePageLink}/notice/143`}>충전요금</a>
          <a href={`${homePageLink}/notice/181`}>에버온 충전소 찾기</a>
          <a href={redirectStore()}>에버온 스마트 충천</a>
        </SubLinkHideContents>
      </Wrapper>

      {/* SIDE MENU */}
      <SideMenuWrap style={style}>
        <SideBox>
          {sideMenuList.map(({ label, link }) => {
            return (
              <SideMenu key={label} onClick={() => onMovePage({ label, link })}>
                {label}
              </SideMenu>
            );
          })}
        </SideBox>
      </SideMenuWrap>
    </HeaderWrap>
  );
};

export default Header;

const HeaderWrap = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: auto;
  z-index: ${zIndex.HEADER};
`;

const Wrapper = styled.div<{ headerWhite?: boolean }>`
  ${cssTemplate.commonTrasition};

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 ${PC_PADDING_HORIZON};

  ${({ headerWhite }) =>
    headerWhite &&
    css`
      background: ${colors.WHITE};
    `}

  ${media.MIN_PC} {
    padding: 0 ${MIN_PC_PADDING_HORIZON};
  }
  ${media.TABLET} {
    padding: unset;
  }

  ${media.MOBILE} {
    padding: unset;
    height: 60px;
  }
`;

const SubLinkHideContents = styled.div`
  position: absolute;
  top: -20px;
  left: 0;
  opacity: 0;
  cursor: none;
`;

const StyledNav = styled.nav`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20%;

  ${maxWidth};
  width: 100%;

  ${media.TABLET} {
    max-width: 100%;
    padding: 0 ${TABLET_PADDING_HORIZON};
  }

  ${media.MOBILE} {
    max-width: 100%;
    padding: 0 ${MOBILE_PADDING_HORIZON};
  }
`;

const SideMenuWrap = styled.nav<{ isMenuBarOpen?: boolean }>`
  width: 100%;
  transition: ${cssTemplate.commonTrasition};
  overflow: hidden;
  background-color: ${colors.WHITE};
  z-index: ${zIndex.HEADER_SIDE_MENU};
`;

const SideBox = styled.div`
  padding: 16px 8px;
`;

const SideMenu = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 20px;
  cursor: pointer;
  ${cssTemplate.commonTrasition};
  border-radius: 8px;
  ${typo.BODY_06M};

  :hover {
    background-color: ${colors.GRAY_11};
  }
  :active {
    background-color: ${colors.GRAY_11};
  }
`;
