import HomeSection01 from "@components/organism/HomeSection01";
import HomeSection02 from "@components/organism/HomeSection02";
import HomeSection03 from "@components/organism/HomeSection03";
import HomeSection04 from "@components/organism/HomeSection04";
import HomeSection05 from "@components/organism/HomeSection05";
import HomeSection06 from "@components/organism/HomeSection06";
import HomeSection07 from "@components/organism/HomeSection07";
import HomeSection08 from "@components/organism/HomeSection08";
import HomeSection09 from "@components/organism/HomeSection09";
import HomeSection10 from "@components/organism/HomeSection10";
import Layout from "@components/template/Layout";
import { useIntersecting } from "@hooks/useIntersecting";
import noticeListState from "@recoil/noticeListState";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSetRecoilState } from "recoil";

export type MenuItem = "compony" | "service" | "produce" | "install";

const Home: React.FC = () => {
  const resetNoticeList = useSetRecoilState(noticeListState);
  useEffect(() => {
    resetNoticeList({ pageSize: 10 });
  }, [resetNoticeList]);

  const [activeMenu, setActiveMenu] = useState<MenuItem>("compony");
  // console.log("✅ >>>> MENU ACTIVE : ", activeMenu);

  const { ref: componyRef } = useIntersecting({
    menuItem: "compony",
    setState: setActiveMenu,
    options: {
      threshold: 0.35,
    },
  });

  const { ref: serviceRef } = useIntersecting({
    menuItem: "service",
    setState: setActiveMenu,
    options: {
      threshold: 0,
    },
  });
  const { ref: produceRef } = useIntersecting({
    menuItem: "produce",
    setState: setActiveMenu,
    options: {
      threshold: 0,
    },
  });

  const { ref: installRef } = useIntersecting({
    menuItem: "install",
    setState: setActiveMenu,
    options: {
      threshold: 0.7,
    },
  });

  return (
    <Layout activeMenu={activeMenu}>
      <div>
        <Helmet>
          <meta name="description" content="회사소개" />
          <meta name="keyword" content="에버온, 회사소개, 회사소개 안내" />
        </Helmet>
        {/* 배너 */}
        <HomeSection01 ref={componyRef} id="compony" />
        {/* 에버온은 끊임없이... */}
        <HomeSection02 />
        {/* 앞서가는 서비스로... */}
        <HomeSection03 />
      </div>

      <div ref={serviceRef}>
        <Helmet>
          <meta name="description" content="핵심서비스" />
          <meta
            name="keyword"
            content="핵심서비스, 바로ON, 모바일 앱, 현장대응, 상시 점검 서비스, 원격 장애 대응 시스템, 전국 출동 서비스, 실시간 모니터링 시스템"
          />
        </Helmet>

        {/* 더욱 편리한 스마트 모바일... */}
        <HomeSection04 ref={serviceRef} id="service" />

        {/* 고객이 안심하고 충전할 수 있도록... */}
        <HomeSection05 />

        {/* 국내 최고 품질의 충전기를 자체 개발하여... */}
        <HomeSection07 />

        {/* 지속적인 연구를 통해... */}
        <HomeSection06 />
      </div>

      <div ref={produceRef}>
        <Helmet>
          <meta name="description" content="대표사례" />
          <meta
            name="keyword"
            content="대표사례, 에버온, 대표사례 안내, 에버온 대표사례"
          />
        </Helmet>

        {/* 공간의 가치를 높여줄 최상의 충전환경을 디자인합니다 */}
        <HomeSection08 id="produce" />

        {/* 국내 최고의 파트너와 전국... */}
        <HomeSection09 />
      </div>

      <div>
        <Helmet>
          <meta name="description" content="설치문의" />
          <meta name="keyword" content="설치문의, 에버온, 설치문의 안내" />
        </Helmet>
        {/* 전기차 충전기 설치를 희망하신다면... */}
        <HomeSection10 ref={installRef} id="install" />
      </div>
    </Layout>
  );
};

export default Home;
