import Text from "@components/atoms/Text";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import { footerList } from "@constants/footer/footer";
import useRouter from "@hooks/useRouter";
import {
  colors,
  maxWidth,
  media,
  MOBILE_PADDING_HORIZON,
  TABLET_PADDING_HORIZON,
  typo,
} from "@styles/index";
import React from "react";

import styled from "styled-components";

interface FooterProps {}

const Footer: React.FC<FooterProps> = ({}) => {
  const { push } = useRouter();
  const nowDate = new Date();
  const nowYear = nowDate.getFullYear();
  return (
    <StyledDiv>
      <div>
        <FooterInnerWrap>
          <LeftFooterWrap>
            <NoticeWrap>
              {footerList.notice.contents.map(({ content, link }) => {
                return (
                  <StyledText key={content} onClick={() => push(link)}>
                    {content}
                  </StyledText>
                );
              })}
            </NoticeWrap>

            {footerList.businessInfo.map(
              ({ title, pcInfo, tabletInfo, mobileInfo }) => (
                <CompanyWrap key={title}>
                  <Text color={colors.WHITE} size={typo.HEADING_07}>
                    {title}
                  </Text>

                  <ScreenResizing
                    pc={
                      <>
                        {[pcInfo.part1, pcInfo.part2, pcInfo.part3].map(
                          (part) => {
                            return (
                              <TextWrap key={part[0].title}>
                                {part.map(({ content, title: partTitle }) => {
                                  return (
                                    <React.Fragment key={partTitle}>
                                      <Text
                                        color={colors.GRAY_08}
                                        size={typo.BODY_07R}
                                      >
                                        {partTitle}
                                      </Text>
                                      <Text
                                        color={colors.WHITE}
                                        size={typo.BODY_07R}
                                      >
                                        {content}
                                      </Text>
                                    </React.Fragment>
                                  );
                                })}
                              </TextWrap>
                            );
                          },
                        )}
                      </>
                    }
                    tablet={
                      <TextWrap>
                        {[tabletInfo.part1, tabletInfo.part2].map((part) => {
                          return (
                            <TextWrap key={part[0].title}>
                              {part.map(({ content, title: partTitle }) => {
                                const LAST_CONTENT = "02-2039-2124";

                                return (
                                  <React.Fragment key={partTitle}>
                                    <Text
                                      color={colors.GRAY_08}
                                      size={typo.BODY_09R}
                                    >
                                      {partTitle} :
                                    </Text>
                                    <Text
                                      color={colors.GRAY_08}
                                      size={typo.BODY_09R}
                                    >
                                      {!content.includes(LAST_CONTENT)
                                        ? content + " | "
                                        : content}
                                    </Text>
                                  </React.Fragment>
                                );
                              })}
                            </TextWrap>
                          );
                        })}
                      </TextWrap>
                    }
                    mobile={
                      <TextWrap>
                        <Text>{mobileInfo}</Text>
                      </TextWrap>
                    }
                  />
                </CompanyWrap>
              ),
            )}
          </LeftFooterWrap>

          <RightFooterWrap>
            {footerList.rightContents.map(({ title, content }) => {
              return (
                <div key={title}>
                  <RightLabelText color={colors.GRAY_08} size={typo.HEADING_07}>
                    {title}
                  </RightLabelText>
                  <RightText color={colors.WHITE} size={typo.BODY_02M}>
                    {content}
                  </RightText>
                </div>
              );
            })}
          </RightFooterWrap>
        </FooterInnerWrap>
        <CopyRight color={colors.GRAY_08} size={typo.BODY_07R}>
         Copyright © {nowYear} everon Co., Ltd. All rights reserved.
        </CopyRight>
      </div>
    </StyledDiv>
  );
};

export default Footer;

const StyledDiv = styled.div`
  width: 100%;
  height: 536px;
  background-color: ${colors.GRAY_03};
  color: ${colors.WHITE};
  padding: 80px 130px;

  > div {
    ${maxWidth}
    margin: 0 auto;
  }

  ${media.TABLET} {
    min-height: 557px;
    padding: 60px ${TABLET_PADDING_HORIZON};
  }
  ${media.MOBILE} {
    height: auto;
    padding: 60px ${MOBILE_PADDING_HORIZON};
  }
`;

const FooterInnerWrap = styled.div`
  width: 100%;
  /* padding: 80px 0; */
  display: flex;
  margin: 0 auto;
  justify-content: space-between;

  > div:nth-child(1) {
    flex: 4;
  }

  > div:nth-child(2) {
    flex: 1;
  }

  ${media.TABLET} {
    flex-direction: column;
    /* padding: 60px 0; */
    gap: 30px;

    > div:nth-child(1) {
      flex: unset;
    }
  }
  ${media.MOBILE} {
    max-width: unset;
    flex-direction: column;
    /* padding: 60px 0; */
  }
`;

const LeftFooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${media.TABLET} {
    gap: 30px;
  }
`;

const RightFooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${media.TABLET} {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }

  ${media.MOBILE} {
    display: grid;
    gap: 30px;
  }
`;

const NoticeWrap = styled.div`
  display: flex;
  gap: 28px;

  ${media.TABLET} {
    gap: 8px;
  }
`;

const StyledText = styled(Text)`
  color: ${colors.GRAY_08};
  cursor: pointer;
  ${typo.BODY_07R};

  ${media.MOBILE} {
    ${typo.BODY_09R};
  }
`;

const CompanyWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.TABLET} {
    > p {
      ${typo.HEADING_09};
    }
  }
`;

const TextWrap = styled.div`
  display: flex;
  gap: 5px;

  > p:nth-child(2) {
    margin-right: 10px;
  }

  ${media.TABLET} {
    flex-wrap: wrap;
    gap: 0 4px;
    > p {
      display: flex;
      align-items: center;
    }

    > p:nth-child(2) {
      margin-right: 0;
    }
  }
  ${media.MOBILE} {
    > p {
      ${typo.BODY_09R};
      color: ${colors.GRAY_08};
    }
  }
`;

const RightLabelText = styled(Text)`
  /*  */
  color: ${colors.GRAY_08};
  ${typo.HEADING_07};
  ${media.TABLET} {
    ${typo.HEADING_09}
  }

  ${media.MOBILE} {
    ${typo.BODY_09M};
  }
`;

const RightText = styled(Text)`
  /*  */
  color: ${colors.WHITE};
  ${typo.BODY_02M};
  ${media.TABLET} {
    ${typo.BODY_03M};
  }

  ${media.MOBILE} {
    ${typo.BODY_03M};
  }
`;

const CopyRight = styled(Text)`
  margin-top: 36px;
  ${media.TABLET} {
    ${typo.BODY_09R}
  }

  ${media.MOBILE} {
    margin-top: 30px;
  }
`;
