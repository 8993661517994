import Button from "@components/atoms/Button";
import Icon from "@components/atoms/Icon";
import Text from "@components/atoms/Text";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import { QnaOrder } from "@constants/home";
import { colors, cssTemplate, media, typo } from "@styles/index";
import React from "react";
import styled from "styled-components";

interface QnaCardProps {
  data: QnaOrder;
  handleDownloadFormModalOpen: () => void;
  handleRecruitingModalOpen: () => void;
  isAnimation: boolean;
}

const QnaCard: React.FC<QnaCardProps> = ({
  data,
  handleDownloadFormModalOpen,
  handleRecruitingModalOpen,
  isAnimation,
}) => {
  const { iconSrc, title, description, buttonText, link } = data;
  return (
    <StyledCard theme={{ isAnimation }}>
      <ScreenResizing
        pc={<Icon backgroundSrc={iconSrc} width={68} height={68} />}
        tablet={<Icon backgroundSrc={iconSrc} width={68} height={68} />}
        mobile={<Icon backgroundSrc={iconSrc} width={48} height={48} />}
      />

      <div>
        <TextWrap>
          <Title size={typo.HEADING_03}>{title}</Title>
          <Description
            size={typo.BODY_07R}
            color={colors.GRAY_06}
            wordBreak="keep-all"
          >
            {description}
          </Description>
        </TextWrap>
        {buttonText && (
          <StyledButton
            pcBtnSize="medium"
            btnColor="deepGray"
            onClick={() => {
              if (link === "RecruitingModal") handleRecruitingModalOpen();
              if (link === "downloadFormModal") handleDownloadFormModalOpen();
            }}
          >
            {buttonText}
          </StyledButton>
        )}
      </div>
    </StyledCard>
  );
};

export default QnaCard;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;

  ${(props) => cssTemplate.customFadeInDown(props)}

  ${media.TABLET} {
    max-width: unset;
    flex: unset;
    gap: 20px;
  }
  ${media.MOBILE} {
    max-width: unset;
    gap: 20px;
  }
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;

  ${media.TABLET} {
    gap: 8px;
  }
`;

const Title = styled(Text)`
  ${media.TABLET} {
    ${typo.HEADING_05}
  }
`;

const Description = styled(Text)`
  ${media.TABLET} {
    max-width: 210px;
    ${typo.BODY_06R};
  }

  ${media.MOBILE} {
    max-width: unset;
  }
`;

const StyledButton = styled(Button)`
  font-weight: 400;
`;
