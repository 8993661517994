import { ICONS } from "@assets/index";
import CommonWrap from "@components/atoms/CommonWrap";
import Icon from "@components/atoms/Icon";
import Text from "@components/atoms/Text";
import FunctionCard from "@components/molecule/FunctionCard";
import IconButton from "@components/molecule/IconButton";
import BaseSection from "@components/template/Layout/BaseSection";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import TitleWrap from "@components/TitleWrap";
import { appStore, googlePlay } from "@constants/home";
import { AnimationCommonProps } from "@constants/types";
import { useIntersecting } from "@hooks/useIntersecting";
import { SubTitle, Title } from "@styles/CommonStyled";
import {
  colors,
  cssTemplate,
  media,
  MIN_PC_PADDING_HORIZON,
  MOBILE_PADDING_HORIZON,
  MOBILE_PADDING_VERTICAL,
  PC_PADDING_HORIZON,
  PC_PADDING_VERTICAL,
  TABLET_PADDING_HORIZON,
  TABLET_PADDING_VERTICAL,
  typo,
} from "@styles/index";
import { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";

interface HomeSection04Props extends AnimationCommonProps {}

export interface FunctionCardInfo {
  isLottie?: boolean;
  imageSrc: string;
  title: string;
  description: string;
  bgColor: string;
}

const HomeSection04 = forwardRef<any, HomeSection04Props>((props, ref) => {
  const { id } = props;
  const functionCardInfos: FunctionCardInfo[] = [
    {
      isLottie: true,
      imageSrc: "card01",
      title: `회원가입 즉시\n모바일로 바로 충전`,
      description: `플라스틱 카드 신청도, 배송도 필요 없어요 `,
      bgColor: "#F1F8FF",
    },

    {
      isLottie: true,
      imageSrc: "card02",
      title: `충전할때는,\n원클릭 충전, 바로ON`,
      description: "카드태깅, QR스캔도 필요 없어요",
      bgColor: "#F1F8FF",
    },
    {
      isLottie: true,
      imageSrc: "card03",
      title: `다양한 결제수단과\n특별한 혜택`,
      description: `간편결제, 포인트, 쿠폰으로 알뜰하게 충전하세요.`,
      bgColor: "#F1F8FF",
    },
  ];

  const appDownloadInfos = [
    {
      label: "App Store",
      link: appStore,
      iconSrc: ICONS.APPLE,
      qrcode: "/images/appstore.png",
    },
    {
      label: "Google Play",
      link: googlePlay,
      iconSrc: ICONS.ANDROID,
      qrcode: "/images/google-store.png",
    },
  ];

  const { ref: sectionRef, isIntersecting: isTitleIntersecting } =
    useIntersecting({
      options: { threshold: 1 },
    });
  const [isTitlteAnimation, setIsTitleAnimation] = useState<boolean>(false);

  useEffect(() => {
    if (isTitleIntersecting) setIsTitleAnimation(true);
  }, [isTitleIntersecting]);

  return (
    <StyledBaseSection ref={ref} fullWidth autoHeight flexCenter>
      <StyledCommonWrap>
        {/* TitleWrap */}
        <StyledTitleWrap
          id={id}
          ref={sectionRef}
          theme={{ isAnimation: isTitlteAnimation }}
        >
          <SubTitle type="h3" textCenter>
            Mobile App
          </SubTitle>
          <Title type="h2" whiteSpace="pre-line" textCenter>
            {`더욱 편리한 스마트 모바일\n솔루션을 제공합니다`}
          </Title>
        </StyledTitleWrap>

        {/* CardWrap */}
        <CardWrap>
          {functionCardInfos.map((info) => {
            return <FunctionCard key={info.title} data={info} />;
          })}
        </CardWrap>

        {/* QR Contents */}
        <TextWrap>
          <ScreenResizing
            pc={
              <Text size={typo.DISPLAY_03} whiteSpace="pre-line">
                {`에버온 스마트 모바일 앱은\n최상의 편리함으로 계속 ON 됩니다.`}
              </Text>
            }
            tablet={
              <Text size={typo.DISPLAY_04} whiteSpace="pre-line">
                {`에버온 스마트 모바일 앱은\n최상의 편리함으로 계속 ON 됩니다.`}
              </Text>
            }
            mobile={
              <Text size={typo.HEADING_01} whiteSpace="pre-line">
                {`에버온 스마트 모바일 앱은\n최상의 편리함으로\n계속 ON 됩니다.`}
              </Text>
            }
          />
          <QrCodeWrap>
            {appDownloadInfos.map(({ iconSrc, label, link, qrcode }) => {
              return (
                <QrCodeBox key={label}>
                  <ScreenResizing
                    pcCenter
                    pc={
                      <Icon backgroundSrc={qrcode} width={120} height={120} />
                    }
                    tablet={
                      <Icon backgroundSrc={qrcode} width={120} height={120} />
                    }
                    mobile={
                      <Icon backgroundSrc={qrcode} width={160} height={160} />
                    }
                  />
                  <StyledIconButton iconSrc={iconSrc} link={link}>
                    {label}
                  </StyledIconButton>
                </QrCodeBox>
              );
            })}
          </QrCodeWrap>
        </TextWrap>
      </StyledCommonWrap>
    </StyledBaseSection>
  );
});

HomeSection04.displayName = "HomeSection04";

export default HomeSection04;

const StyledBaseSection = styled(BaseSection)`
  padding: ${PC_PADDING_VERTICAL} ${PC_PADDING_HORIZON};

  ${media.MIN_PC} {
    padding: ${PC_PADDING_VERTICAL} 80px;
  }

  ${media.TABLET} {
    padding: ${TABLET_PADDING_VERTICAL} ${TABLET_PADDING_HORIZON};
  }
  ${media.MOBILE} {
    padding: ${MOBILE_PADDING_VERTICAL} ${MOBILE_PADDING_HORIZON};
  }
`;

const StyledCommonWrap = styled(CommonWrap)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  height: unset;

  ${media.TABLET} {
    gap: 60px;
  }
  ${media.MOBILE} {
    gap: 40px;
  }
`;

const StyledTitleWrap = styled(TitleWrap)`
  ${(props) => cssTemplate.customFadeInDown(props)}

  ${media.MOBILE} {
    gap: 8px;
  }
`;

const CardWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  width: 100%;

  ${media.TABLET} {
    gap: 24px;
  }

  ${media.MOBILE} {
    align-items: center;
    justify-content: center;
  }
`;

const TextWrap = styled(TitleWrap)`
  margin-top: 80px;
  text-align: center;
  gap: 80px;

  ${media.TABLET} {
    margin-top: 60px;
    gap: 60px;
  }

  ${media.MOBILE} {
    margin-top: 40px;
  }
`;

const QrCodeWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 120px;

  ${media.TABLET} {
    gap: 60px;
  }

  ${media.MOBILE} {
    flex-direction: column;
    align-items: center;
  }
`;

const QrCodeBox = styled.div`
  /*  */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 240px;
  min-height: 224px;
  gap: 48px;

  ${media.MOBILE} {
    max-width: 240px;
    min-height: 256px;
  }
`;

const StyledIconButton = styled(IconButton)`
  min-width: 240px;

  ${media.MOBILE} {
    height: 56px;
  }
`;
