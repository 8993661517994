import { colors, typo } from "@styles/index";
import { Input } from "antd";
import React from "react";
import styled from "styled-components";

interface AntdInputProps {
  placeholder: string;
  isAllowClear?: boolean;
  onChange?: any;
  name?: string;
  type?: string;
  inputMode?:
    | "text"
    | "search"
    | "email"
    | "tel"
    | "url"
    | "none"
    | "numeric"
    | "decimal"
    | undefined;

  [key: string]: any;
  value?: string;
  maxLength?: number;
}

const AntdInput: React.FC<AntdInputProps> = ({
  placeholder,
  isAllowClear = true,
  name,
  onChange,
  type,
  inputMode,
  value,
  maxLength,
  ...res
}) => {
  return (
    <StyledInput
      name={name}
      allowClear={isAllowClear}
      placeholder={placeholder}
      onChange={onChange}
      type={type}
      inputMode={inputMode}
      // 자동 대소문자 변환
      autoCapitalize="off"
      value={value}
      maxLength={maxLength}
      {...res}
    />
  );
};

export default AntdInput;

const StyledInput = styled(Input)`
  /* 자동완성시 백그라운드 색 제거 */
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
    -webkit-text-fill-color: #000;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  /* number input 화살표 제거 */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  padding: 14px 20px;
  ${typo.BODY_07R};
  border-radius: 12px;

  /* border-color */
  :where(.css-dev-only-do-not-override-1vtf12y).ant-input-affix-wrapper:not(
      .ant-input-affix-wrapper-disabled
    ):hover {
    border-color: ${colors.PRIMARY};
  }
  :where(.css-dev-only-do-not-override-1vtf12y).ant-input-affix-wrapper:focus,
  :where(
      .css-dev-only-do-not-override-1vtf12y
    ).ant-input-affix-wrapper-focused {
    border-color: ${colors.PRIMARY};
  }

  /* clear button size */
  :where(.css-dev-only-do-not-override-1vtf12y).ant-input-affix-wrapper
    .ant-input-clear-icon {
    font-size: 1.125rem;
  }
`;
