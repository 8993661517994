import IconBox from "@components/atoms/IconBox";
import Text from "@components/atoms/Text";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import { ServiceList } from "@constants/home";
import { colors, cssTemplate, grid, media, typo } from "@styles/index";
import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

interface ServiceCardProps {
  data: ServiceList;
  isAnimation: boolean;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ data, isAnimation }) => {
  const { title, iconSrc, pcDescription, mobileDescripon, tabletDescription } =
    data;

  const isMin_Pc = useMediaQuery({ minWidth: grid.MIN_PC + 1 });

  return (
    <StyledDiv theme={{ isAnimation }}>
      <ScreenResizing
        pc={
          <IconBox
            boxBgColor={colors.WHITE}
            src={iconSrc}
            iconWidth={68}
            iconHeight={68}
          />
        }
        tablet={
          <IconBox
            boxBgColor={colors.WHITE}
            src={iconSrc}
            iconWidth={68}
            iconHeight={68}
          />
        }
        mobile={
          <IconBox
            src={iconSrc}
            width={48}
            height={48}
            iconWidth={48}
            iconHeight={48}
            boxBgColor={colors.WHITE}
          />
        }
      />

      <TextWrap>
        <Label size={typo.HEADING_02}>{title}</Label>
        <ScreenResizing
          pc={
            <Description
              size={typo.BODY_06R}
              color={colors.GRAY_04}
              whiteSpace="pre-line"
              wordBreak={isMin_Pc ? "break-all" : "keep-all"}
            >
              {isMin_Pc ? pcDescription : tabletDescription}
            </Description>
          }
          tablet={
            <Description
              size={typo.BODY_06R}
              color={colors.GRAY_04}
              whiteSpace="pre-line"
              wordBreak="keep-all"
            >
              {tabletDescription}
            </Description>
          }
          mobile={
            <Description
              size={typo.BODY_08R}
              color={colors.GRAY_04}
              whiteSpace="pre-line"
              wordBreak="keep-all"
            >
              {mobileDescripon}
            </Description>
          }
        />
      </TextWrap>
    </StyledDiv>
  );
};

export default ServiceCard;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
  background: ${colors.WHITE};
  border-radius: 16px;

  ${(props) => cssTemplate.customFadeInDown(props)}

  ${media.TABLET} {
    padding: 40px 32px;
  }

  ${media.MOBILE} {
    padding: 24px;
    gap: 20px;
    flex-direction: row;
  }
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.MOBILE} {
    gap: 8px;
  }
`;

const Label = styled(Text)`
  ${media.MOBILE} {
    ${typo.HEADING_05};
  }
`;

const Description = styled(Text)``;
