export const footerList = {
  notice: {
    contents: [
      {
        content: "공지사항",
        link: "/notice",
      },
      {
        content: "회원 이용약관",
        link: "/terms/records?category=service-use-policy",
      },
      {
        content: "개인정보처리방침",
        link: "/terms/records?category=privacy-policy",
      },
      {
        content: "투자정보",
        link: "/ir-notice",
      },
    ],
  },
  businessInfo: [
    {
      index: 0,
      title: "주식회사 에버온",
      pcInfo: {
        part1: [
          { title: "대표자", content: "유동수" },
          {
            title: "통신판매업 신고번호",
            content: "제2022-서울중구-0381호",
          },
          {
            title: "사업자등록번호",
            content: "105-87-79517",
          },
        ],
        part2: [
          {
            title: "주소",
            content: "04551 서울특별시 중구 을지로 100 파인에비뉴 B동 3층",
          },
        ],
        part3: [
          {
            title: "전화",
            content: "1661-7766",
          },
          {
            title: "팩스",
            content: "02-2039-2124",
          },
        ],
      },
      tabletInfo: {
        part1: [
          { title: "대표자", content: "유동수" },
          {
            title: "통신판매업 신고번호",
            content: "제2022-서울중구-0381호",
          },
          {
            title: "사업자등록번호",
            content: "105-87-79517",
          },
        ],
        part2: [
          {
            title: "주소",
            content: "04551 서울특별시 중구 을지로 100 파인에비뉴 B동 3층",
          },
          {
            title: "전화",
            content: "1661-7766",
          },
          {
            title: "팩스",
            content: "02-2039-2124",
          },
        ],
      },
      mobileInfo: `대표자 유동수 | 통신판매업 신고번호 제2022-서울중구-0381호 | 사업자등록번호 105-87-79517 | 주소 04551 서울특별시 중구 을지로 100 파인에비뉴 B동 3층 | 전화 1661-7766 | 팩스 02-2039-2124`,
    },
    {
      index: 1,
      title: "주식회사 에버온인프라",
      pcInfo: {
        part1: [
          { title: "대표자", content: "유동수" },
          {
            title: "사업자등록번호",
            content: "330-86-02392",
          },
        ],
        part2: [
          {
            title: "주소",
            content: "04551 서울특별시 중구 을지로 100 파인에비뉴 B동 3층",
          },
        ],
        part3: [
          {
            title: "전화",
            content: "1661-7766",
          },
          {
            title: "팩스",
            content: "02-2039-2124",
          },
        ],
      },
      tabletInfo: {
        part1: [
          { title: "대표자", content: "유동수" },
          {
            title: "사업자등록번호",
            content: "330-86-02392",
          },
          {
            title: "주소",
            content: "04551 서울특별시 중구 을지로 100 파인에비뉴 B동 3층",
          },
        ],
        part2: [
          {
            title: "전화",
            content: "1661-7766",
          },
          {
            title: "팩스",
            content: "02-2039-2124",
          },
        ],
      },
      mobileInfo: `대표자 유동수 | 사업자등록번호 330-86-02392 | 주소 04551 서울특별시 중구 을지로 100 파인에비뉴 B동 3층 | 전화 1661-7766 | 팩스 02-2039-2124`,
    },
  ],
  rightContents: [
    {
      title: "설치 및 영업문의",
      content: "sales@everon.co.kr",
    },
    {
      title: "제휴문의",
      content: "coop@everon.co.kr",
    },
    {
      title: "고객센터",
      content: "1661 - 7766",
    },
  ],
};
