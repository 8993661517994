import { ICONS } from "@assets/index";
import AntdInput from "@components/atoms/AntdInput";
import AntdTextArea from "@components/atoms/AntdTextArea";
import Button from "@components/atoms/Button";
import IconBox from "@components/atoms/IconBox";
import Text from "@components/atoms/Text";
import { RecrutingType } from "@components/organism/RecruitingModal";
import TermsModal from "@components/organism/TermsModal";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import { ColumnWrap } from "@styles/CommonStyled";
import { colors, media, typo } from "@styles/index";
import React, { useState } from "react";
import styled from "styled-components";

interface RecruitingInputSectionProps {
  handleModalClose: () => void;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  isTermsAgree: boolean;
  setIsTermsAgree: React.Dispatch<React.SetStateAction<boolean>>;
  isFormComplete: boolean;
  formReset?: () => void;
  onSubmit?: () => void;
  values?: RecrutingType;
}

const RecruitingInputSection: React.FC<RecruitingInputSectionProps> = ({
  onChange,
  onSubmit,
  isTermsAgree,
  setIsTermsAgree,
  isFormComplete,
  values,
}) => {
  const [isTermsModal, setIsTermsModal] = useState(false);

  const openTermsModal = () => {
    setIsTermsModal(true);
  };

  const closeTermsModal = () => {
    setIsTermsModal(false);
  };

  const onPhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;
    const { value } = event.target;
    const regex = /^[0-9\b -]{0,11}$/;

    if (value === "" || regex.test(value)) {
      onChange(event);
    }
  };

  return (
    <>
      <InputSection>
        <InputWrap>
          <StyledColumnWrap transFlexDirection="row" gap={16}>
            <AntdInput
              name="clientName"
              onChange={onChange}
              placeholder="이름"
              value={values?.clientName}
            />

            <AntdInput
              name="phoneNumber"
              onChange={onPhoneNumberChange}
              placeholder="연락처 (필수)"
              type="string"
              inputMode="numeric"
              value={values?.phoneNumber}
              maxLength={11}
            />
          </StyledColumnWrap>
          <AntdInput
            name="email"
            onChange={onChange}
            placeholder="이메일"
            inputMode="email"
            value={values?.email}
          />
          <AntdInput name="address" onChange={onChange} placeholder="주소" />
          <AntdTextArea
            height={200}
            name="content"
            placeholder="작성 TIP을 확인하고 내용을 작성해주세요."
            onChange={onChange}
            value={values?.content}
          />
        </InputWrap>

        <ScreenResizing
          pc={
            <SubmitWrap>
              <ColumnWrap transFlexDirection="row" gap={107}>
                <TermsTextWrap
                  onClick={() => {
                    setIsTermsAgree((prev: boolean) => {
                      return !prev;
                    });
                  }}
                >
                  <IconBox
                    src={ICONS.CHECK}
                    iconWidth={16}
                    iconHeight={16}
                    width={24}
                    height={24}
                    radius={8}
                    boxBgColor={isTermsAgree ? colors.PRIMARY : colors.GRAY_08}
                    isCursor
                  />
                  <StyledText size={typo.BODY_06R}>
                    <span>[필수]</span> 개인정보 수집 및 이용 안내에 대한 동의
                  </StyledText>
                </TermsTextWrap>

                <TermsDetailText
                  onClick={openTermsModal}
                  size={typo.BODY_08R}
                  color={colors.GRAY_06}
                >
                  약관 상세보기
                </TermsDetailText>
              </ColumnWrap>
              <StyledButton
                pcBtnSize="extra"
                disabled={!isFormComplete}
                onClick={() => {
                  if (!onSubmit) return;
                  onSubmit();
                }}
              >
                제출하기
              </StyledButton>
            </SubmitWrap>
          }
        />
      </InputSection>

      <TermsModal
        isModalOpen={isTermsModal}
        handleModalClose={closeTermsModal}
      />
    </>
  );
};

export default RecruitingInputSection;

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledText = styled(Text)`
  > span {
    ${typo.BODY_06M}
  }
`;

const TermsTextWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

const TermsDetailText = styled(Text)`
  text-decoration: underline;
  text-underline-position: under;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const SubmitWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledColumnWrap = styled(ColumnWrap)`
  ${media.TABLET} {
    flex-direction: column;
  }
`;
