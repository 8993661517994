import { colors, cssTemplate } from "@styles/index";
import React from "react";
import styled, { css } from "styled-components";
import Icon from "../Icon";

interface IconBoxProps {
  src: string;
  boxBgColor?: string;
  width?: number | string;
  height?: number | string;
  iconWidth?: number | string;
  iconHeight?: number | string;
  isCursor?: boolean;
  radius?: number;
  onClick?: () => void;
}

const IconBox: React.FC<IconBoxProps> = (props) => {
  const {
    boxBgColor,
    src,
    width,
    height,
    iconWidth,
    iconHeight,
    radius,
    isCursor,
    onClick,
    ...res
  } = props;
  return (
    <>
      <StyledBox
        bgColor={boxBgColor}
        width={width}
        height={height}
        radius={radius}
        isCursor={isCursor}
        onClick={onClick}
        {...res}
      >
        <Icon backgroundSrc={src} width={iconWidth} height={iconHeight} />
      </StyledBox>
    </>
  );
};

export default IconBox;

const StyledBox = styled.div<{
  bgColor?: string;
  width?: number | string;
  height?: number | string;
  radius?: number;
  isCursor?: boolean;
}>`
  background: ${({ bgColor }) => (bgColor ? bgColor : `${colors.PRIMARY_06}`)};
  width: ${({ width }) => (width ? width : 80)}px;
  height: ${({ height }) => (height ? height : 80)}px;
  border-radius: ${({ radius }) => (radius ? radius : 16)}px;

  ${({ isCursor }) =>
    isCursor &&
    css`
      cursor: pointer;
    `}

  display: flex;
  justify-content: center;
  align-items: center;
  ${cssTemplate.commonTrasition};
`;
