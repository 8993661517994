import React, { useEffect } from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import styled, { css } from "styled-components";
import Icon from "@components/atoms/Icon";
import { ICONS } from "@assets/index";
import { colors, media, typo } from "@styles/index";
import { ZoneCategoryImages } from "@constants/home";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import { valueType } from "antd/es/statistic/utils";

const variants = {
  enter: (direction: number) => {
    return {
      // x: direction > 0 ? 10 : -10,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      // x: direction < 0 ? 10 : -10,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

interface ImageSliedProps {
  images: ZoneCategoryImages[];
  imageIndex: number;
  setPage: React.Dispatch<React.SetStateAction<[number, number]>>;
  page: number;
  direction: number;
}

export const ImageSlied: React.FC<ImageSliedProps> = ({
  images,
  direction,
  imageIndex,
  page,
  setPage,
}) => {
  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };
  // const categoryCnt = images.reduce((prev, curr) => {
  //   prev[curr.category] = (prev[curr.category] || 0) + 1;
  //   return prev;
  // }, {} as Record<string, number>); 
  // const categoryNum = categoryCnt[images[imageIndex].category];
  // const devideNum = categoryNum > 3 ? 5 : 3;
  return (
    <>
      <StyledImageWrap>
        <AnimatePresence initial={false} custom={direction}>
          <motion.img
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              borderRadius: "20px",
            }}
            key={page}
            src={images[imageIndex].src}
            alt={images[imageIndex].text}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              opacity: { duration: 1 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            loading="eager"
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          />
        </AnimatePresence>

        <ScreenResizing
          pc={
            <>
              <NextButton className="next" onClick={() => paginate(1)}>
                <Icon backgroundSrc={ICONS.ARROW_RIGHT} />
              </NextButton>
              <PrevButton className="prev" onClick={() => paginate(-1)}>
                <Icon backgroundSrc={ICONS.ARROW_LEFT} />
              </PrevButton>
            </>
          }
          tablet={
            <>
              <NextButton className="next" onClick={() => paginate(1)}>
                <Icon backgroundSrc={ICONS.ARROW_RIGHT} />
              </NextButton>
              <PrevButton className="prev" onClick={() => paginate(-1)}>
                <Icon backgroundSrc={ICONS.ARROW_LEFT} />
              </PrevButton>
            </>
          }
        />

        <ImageDescription style={{ position: "absolute", right: 0, zIndex: 1 }}>
          {images[imageIndex].text}
        </ImageDescription>
      </StyledImageWrap>
      <ImgIndexWrap>
        {Array.from(Array(images.length / 4).keys()).map((_, i) => {
          return <ImgIndex key={i} isActive={i === imageIndex % 3} />;
        })}     
        {/* {images.map((_, i) => {
          return <ImgIndex key={i} isActive={i === imageIndex} />;
        })} */}
        {/* {Array.from(Array(categoryNum)).map((_, i) => {
          return <ImgIndex key={i} isActive={i === imageIndex % devideNum} />;
        })} */}
      </ImgIndexWrap>
    </>
  );
};

const StyledImageWrap = styled.div`
  position: relative;
  height: 675px;
  background-color: white;
  overflow: hidden;
  border-radius: 20px;

  ${media.TABLET} {
    height: 377px;
  }

  ${media.MOBILE} {
    height: 188px;
  }
`;

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 60px;
  height: 88px;

  position: absolute;
  z-index: 1;
  border: none;
  background: rgba(0, 0, 0, 0.6);

  top: 50%;
  transform: translateY(-50%);
`;

const NextButton = styled(StyledButton)`
  right: 0;
  border-radius: 16px 0px 0px 16px;
`;

const PrevButton = styled(StyledButton)`
  left: 0;
  border-radius: 0px 16px 16px 0px;
`;

const ImageDescription = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  z-index: 1;
  background: ${colors.GRAY_02};
  color: ${colors.WHITE};

  width: 300px;
  height: 62px;
  border-radius: 16px 0 16px 0;

  ${typo.BODY_04M};
  font-weight: 400;

  ${media.MOBILE} {
    padding: 12px 16px;
    width: 210px;
    height: 44px;
    ${typo.BODY_09M}
  }
`;

const ImgIndexWrap = styled.ul`
  display: flex;
  gap: 6px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
  /* bottom: -40px; */
`;

const ImgIndex = styled.li<{ isActive: boolean }>`
  width: 8px;
  height: 8px;
  background-color: ${colors.GRAY_08};
  border-radius: 200px;
  transition: 0.5s;

  ${({ isActive }) => {
    if (isActive) {
      return css`
        width: 28px;
        background: ${colors.GRAY_02};

        ${media.TABLET} {
          width: 24px;
        }
      `;
    }
  }}
`;
