import Icon from "@components/atoms/Icon";
import Lottie from "@components/atoms/Lottie";
import Text from "@components/atoms/Text";
import { FunctionCardInfo } from "@components/organism/HomeSection04";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import { useIntersecting } from "@hooks/useIntersecting";
import { colors, cssTemplate, media, shadow, typo } from "@styles/index";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface FunctionCardProps {
  data: FunctionCardInfo;
}

const FunctionCard: React.FC<FunctionCardProps> = (props) => {
  const { data } = props;
  const { bgColor, description, imageSrc, title, isLottie } = data;

  const { ref, isIntersecting: isCardIntersecting } = useIntersecting({
    options: { threshold: 0.6 },
  });
  const { ref: lottieRef, isIntersecting: isLottieIntersecting } =
    useIntersecting({
      options: { threshold: 0.6 },
    });

  const [isCardAnimation, setIsCardAnimation] = useState<boolean>(false);
  const [isLottieAnimation, setIsLottieAnimation] = useState<boolean>(false);

  useEffect(() => {
    if (isCardIntersecting) setIsCardAnimation(true);
  }, [isCardIntersecting]);
  useEffect(() => {
    if (isLottieIntersecting) setIsLottieAnimation(true);
  }, [isLottieIntersecting]);

  return (
    <StyledDiv
      ref={ref}
      bgColor={bgColor}
      theme={{ isAnimation: isCardAnimation }}
    >
      <StyledTextWrap>
        <Title whiteSpace="pre-line">{title}</Title>

        <Description whiteSpace="pre-line">{description}</Description>
      </StyledTextWrap>

      {isLottie && (
        <StyledLottieWrap ref={lottieRef}>
          <ScreenResizing
            pc={
              <Lottie
                lottieName={imageSrc}
                width={461}
                height={461}
                keepLooping={true}
              />
            }
            tabletCenter
            tablet={
              <Lottie
                lottieName={imageSrc}
                width={257}
                height={257}
                keepLooping={true}
              />
            }
            mobileCenter
            mobile={<Lottie lottieName={imageSrc} keepLooping={true} />}
          />
        </StyledLottieWrap>
      )}
    </StyledDiv>
  );
};

export default FunctionCard;

const StyledDiv = styled.div<{ bgColor: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  ${(props) => cssTemplate.customFadeInDown(props)}

  width: 100%;
  height: 572px;

  padding: 56px 80px;
  border-radius: 24px;
  background-color: ${({ bgColor }) => bgColor};

  > div:nth-child(1) {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  ${media.TABLET} {
    height: 320px;
    padding: 36px 40px;
    gap: 40px;

    > div:nth-child(1) {
      width: 100%;
      max-width: 335px;
      gap: 16px;
    }
  }

  ${media.MOBILE} {
    max-width: 335px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    gap: 36px;

    :nth-child(2n) {
      flex-direction: column;
    }

    > div:nth-child(1) {
      width: 100%;
      max-width: 335px;
      text-align: center;
    }
  }
`;

const StyledTextWrap = styled.div`
  max-width: 500px;
`;

const Title = styled(Text)`
  ${typo.DISPLAY_04};

  ${media.TABLET} {
    ${typo.DISPLAY_05};
    font-weight: 600;
  }

  ${media.MOBILE} {
    ${typo.HEADING_02};
  }
`;

const Description = styled(Text)`
  ${typo.BODY_03R};
  color: ${colors.GRAY_06};
  word-break: keep-all;

  ${media.TABLET} {
    ${typo.BODY_08R};
    font-weight: 600;
    max-width: 250px;
  }
`;

const StyledLottieWrap = styled.div`
  width: 460px;
  height: 460px;
  border-radius: 24px;
  background-color: white;

  position: relative;
  overflow: hidden;

  ${shadow.DP_16};

  ${media.TABLET} {
    max-width: 256px;
    width: 100%;
    height: 256px;
  }

  ${media.MOBILE} {
    height: auto;
  }
`;
