import GlobalModal from "@components/template/GlobalModal";
import CorporateSignup from "@pages/CorporateSignup";
import Home from "@pages/Home";
import Notice from "@pages/Notice";
import NoticeDetail from "@pages/NoticeDetail";
import IRNotice from "@pages/IRNotice";
import IRNoticeDetail from "@pages/IRNoticeDetail";
import Terms from "@pages/Terms";
import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { GlobalStyle } from "./styles";


const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>;
};

function App() {

  useEffect(() => {
    if (window.location.pathname === "/chargeMobileWeb/guest/charge") {
      window.location.href = "https://app.everon.co.kr/chargeMobileWeb/guest/charge";
    }
  }, []);

  return (
    <>
      <Wrapper>
        <GlobalStyle />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/notice/:id" element={<NoticeDetail />} />
          <Route path="/ir-notice" element={<IRNotice />} />
          <Route path="/ir-notice/:id" element={<IRNoticeDetail />} />
          <Route path="/terms/records" element={<Terms />} />
          <Route path="/corporate/signup" element={<CorporateSignup />} />
          <Route path="/*" element={<Home />} />
        </Routes>
      </Wrapper>

      <GlobalModal />
    </>
  );
}

export default App;
