import Button from "@components/atoms/Button";
import CommonWrap from "@components/atoms/CommonWrap";
import { ImageSlied } from "@components/molecule/ImageSlied";
import BaseSection from "@components/template/Layout/BaseSection";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import TitleWrap from "@components/TitleWrap";
import { categoryList, zoneList } from "@constants/home";
import { AnimationCommonProps } from "@constants/types";
import { useIntersecting } from "@hooks/useIntersecting";
import useInterval from "@hooks/useInterval";
import { SubTitle, Title } from "@styles/CommonStyled";
import {
  cssTemplate,
  media,
  MIN_PC_PADDING_HORIZON,
  MOBILE_PADDING_HORIZON,
  MOBILE_PADDING_VERTICAL,
  PC_PADDING_HORIZON,
  PC_PADDING_VERTICAL,
  TABLET_PADDING_HORIZON,
  TABLET_PADDING_VERTICAL,
  typo,
} from "@styles/index";
import { wrap } from "framer-motion";
import { forwardRef, MutableRefObject, useEffect, useState } from "react";
import styled, { css } from "styled-components";

interface HomeSection08Props extends AnimationCommonProps {
  ref?: MutableRefObject<any>;
}

const HomeSection08 = forwardRef<any, HomeSection08Props>((props, ref) => {
  const { id } = props;
  const { ref: sectionRef, isIntersecting: isTitleIntersecting } =
    useIntersecting({
      options: { threshold: 0.4 },
    });
  const { ref: imageRef, isIntersecting: isImageIntersecting } =
    useIntersecting({
      options: { threshold: 0.5 },
    });
  const [isTitleAnimation, setIsTitleAnimation] = useState<boolean>(false);
  const [isImageAnimation, setIsImageAnimation] = useState<boolean>(false);

  useEffect(() => {
    if (isTitleIntersecting) setIsTitleAnimation(true);
  }, [isTitleIntersecting]);
  useEffect(() => {
    if (isImageIntersecting) setIsImageAnimation(true);
  }, [isImageIntersecting]);

  const [isInteracted, setIsInteracted] = useState(-1);
  useInterval(
    () => {
      setIsInteracted(isInteracted + 1);
    },
    isTitleIntersecting ? (isInteracted > zoneList.length ? null : 150) : null,
  );

  const [[page, direction], setPage] = useState([0, 0]);
  const imageIndex = wrap(0, zoneList.length, page);

  return (
    <StyledBaseSection ref={ref} fullWidth height="1390px" flexCenter>
      <StyledCommonWrap>
        <StyledTitleWrap
          id={id}
          ref={sectionRef}
          theme={{ isAnimation: isTitleAnimation }}
        >
          <SubTitle type="h3">everon Place</SubTitle>
          <ScreenResizing
            pc={
              <Title type="h2" whiteSpace="pre-line" wordBreak="keep-all">
                {`공간의 가치를 높여줄 최상의 충전환경을 디자인합니다`}
              </Title>
            }
            tablet={
              <Title type="h2" whiteSpace="pre-line" wordBreak="keep-all">
                {`공간의 가치를 높여줄 최상의\n충전환경을 디자인합니다`}
              </Title>
            }
            mobile={
              <Title type="h2" whiteSpace="pre-line" wordBreak="keep-all">
                {`공간의 가치를 높여줄 최상의 충전환경을 디자인합니다`}
              </Title>
            }
          />
        </StyledTitleWrap>

        <ContentsWrap>
          {/* 카테고리 */}
          <CategoryWrap>
            {categoryList.map(({ category, categoryFirst }, index) => {
              return (
                <StyledButton
                  key={category}
                  btnColor="lightGray"
                  pcBtnSize="lager"
                  isActive={category === zoneList[imageIndex].category}
                  theme={{ isAnimation: isInteracted >= index }}
                  onClick={() => {
                    setPage([categoryFirst, 0]);
                  }}
                >
                  #{category}
                </StyledButton>
              );
            })}
          </CategoryWrap>

          {/* 이미지 캐러셀 */}
          <ImageSliedWrap
            ref={imageRef}
            theme={{ isAnimation: isImageAnimation }}
          >
            <ImageSlied
              images={zoneList}
              direction={direction}
              imageIndex={imageIndex}
              page={page}
              setPage={setPage}
            />
          </ImageSliedWrap>
        </ContentsWrap>
      </StyledCommonWrap>
    </StyledBaseSection>
  );
});

HomeSection08.displayName = "HomeSection08";

export default HomeSection08;

const StyledBaseSection = styled(BaseSection)`
  overflow: hidden;

  padding: ${PC_PADDING_VERTICAL} ${PC_PADDING_HORIZON};

  ${media.MIN_PC} {
    padding: ${PC_PADDING_VERTICAL} ${MIN_PC_PADDING_HORIZON};
  }

  ${media.TABLET} {
    height: auto;
    padding: ${TABLET_PADDING_VERTICAL} ${TABLET_PADDING_HORIZON};
  }
  ${media.MOBILE} {
    padding: ${MOBILE_PADDING_VERTICAL} ${MOBILE_PADDING_HORIZON};
  }
`;

const StyledCommonWrap = styled(CommonWrap)`
  display: flex;
  flex-direction: column;
  gap: 56px;
  position: relative;

  ${media.TABLET} {
    gap: 60px;
  }
  ${media.MOBILE} {
    gap: 40px;
  }
`;

const StyledTitleWrap = styled(TitleWrap)`
  ${(props) => cssTemplate.customFadeInDown(props)}

  ${media.MOBILE} {
    gap: 8px;
  }
`;

const ContentsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 56px;

  ${media.TABLET} {
    gap: 48px;
  }
  ${media.MOBILE} {
    gap: 24px;
  }
`;

const CategoryWrap = styled.div`
  display: flex;
  gap: 12px;

  ${media.MOBILE} {
    gap: 11px;
  }
`;

const ImageSliedWrap = styled.div`
  position: relative;
  width: 100%;
  height: 718px;
  overflow: hidden;

  ${(props) => cssTemplate.customFadeInDown(props)}

  ${media.TABLET} {
    height: 401px;
  }

  ${media.MOBILE} {
    height: 212px;
  }
`;

const StyledButton = styled(Button)<{ isActive: boolean }>`
  border-radius: 200px;
  padding: 12px 32px 10px;
  ${typo.BODY_04M};

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: black;
      color: white;

      &:hover {
        background-color: black;
        color: white;
      }
      &:active {
        background-color: black;
        color: white;
      }
    `}

  ${(props) => cssTemplate.customFadeInDown(props)} 

  ${media.MOBILE} {
    padding: 6px 8px;
    ${typo.BODY_09M};
  }
`;
