import { ICONS } from "@assets/index";
import Icon from "@components/atoms/Icon";
import IconBox from "@components/atoms/IconBox";
import Text from "@components/atoms/Text";
import { ProductCardList } from "@constants/home";
import { colors, cssTemplate, media, shadow, typo } from "@styles/index";
import React from "react";
import styled from "styled-components";

interface ProductCardProps {
  data: ProductCardList;
  onClick: () => void;
  isAnimation: boolean;
}

const ProductCard: React.FC<ProductCardProps> = ({
  data: { imageSrc, key, label },
  onClick,
  isAnimation,
}) => {
  return (
    <StyledDiv key={key} onClick={onClick} theme={{ isAnimation }}>
      <CardImgWrap>
        <Image backgroundSrc={imageSrc} />
      </CardImgWrap>
      <TextWrap>
        <Text>{label}</Text>
      </TextWrap>
      <StyledIconBox
        src={ICONS.SEARCH}
        width={40}
        height={40}
        iconWidth={19}
        iconHeight={19}
        boxBgColor={colors.WHITE}
      />
    </StyledDiv>
  );
};

export default ProductCard;

const StyledDiv = styled.div`
  position: relative;
  width: 270px;
  height: 410px;
  border: 1px solid #e7edf0;
  border-radius: 20px;

  overflow: hidden;
  ${cssTemplate.commonTrasition};

  ${(props) => cssTemplate.customFadeInDown(props)}

  cursor: pointer;
  :hover {
    box-shadow: 4px 20px 40px rgba(53, 58, 63, 0.16);
  }

  ${media.TABLET} {
    width: 100%;
    height: 326px;
  }
`;

const CardImgWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 316px;

  background-color: white;

  ${media.TABLET} {
    height: 262px;
  }
`;

const Image = styled(Icon)`
  max-width: 137px;
  width: 100%;
  max-height: 238.29px;
  height: 100%;

  background-size: contain;

  ${media.TABLET} {
    max-width: 112px;
    max-height: 204px;
  }
`;

const TextWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 94px;
  background: ${colors.GRAY_10};

  > p {
    ${typo.HEADING_04};
  }

  ${media.TABLET} {
    height: 64px;

    > p {
      ${typo.HEADING_06};
    }
  }
`;

const StyledIconBox = styled(IconBox)`
  position: absolute;
  top: 24px;
  right: 24px;
  border-radius: 100%;

  ${shadow.DP_8};
`;
