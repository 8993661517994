import CommonWrap from "@components/atoms/CommonWrap";
import Icon from "@components/atoms/Icon";
import Text from "@components/atoms/Text";
import BaseSection from "@components/template/Layout/BaseSection";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import { logoList } from "@constants/home";
import { AnimationCommonProps } from "@constants/types";
import { useCountUp } from "@hooks/useCountUp";
import { useIntersecting } from "@hooks/useIntersecting";
import { SubTitle } from "@styles/CommonStyled";
import {
  ANIMATION_COUNT_VALUE,
  colors,
  cssTemplate,
  media,
  MIN_PC_PADDING_HORIZON,
  MOBILE_PADDING_HORIZON,
  MOBILE_PADDING_VERTICAL,
  PC_PADDING_HORIZON,
  PC_PADDING_VERTICAL,
  TABLET_PADDING_HORIZON,
  TABLET_PADDING_VERTICAL,
  typo,
} from "@styles/index";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface HomeSection09Props extends AnimationCommonProps {}

const HomeSection09: React.FC<HomeSection09Props> = ({ id }) => {
  const { ref: sectionRef, isIntersecting } = useIntersecting({
    options: { threshold: 0.2 },
  });
  const { ref: titleRef, isIntersecting: isLogoWrapIntersecting } =
    useIntersecting({
      options: { threshold: 0.3 },
    });

  const [isAnimationActive, setIsAnimationActive] = useState<boolean>(false);
  const [isLogoWrapAnimation, setIsLogoWrapAnimation] =
    useState<boolean>(false);
  const count = useCountUp(10000, 0, ANIMATION_COUNT_VALUE, isAnimationActive);

  useEffect(() => {
    if (isIntersecting) setIsAnimationActive(true);
  }, [isIntersecting]);
  useEffect(() => {
    if (isLogoWrapIntersecting) setIsLogoWrapAnimation(true);
  }, [isLogoWrapIntersecting]);

  return (
    <StyledBaseSection
      ref={sectionRef}
      id={id}
      fullWidth
      height="1146px"
      flexCenter
    >
      <StyledCommonWrap
        ref={titleRef}
        theme={{ isAnimation: isAnimationActive }}
      >
        <ScreenResizing
          pc={
            <>
              <SubTitle textCenter type="h3">
                Partner Site
              </SubTitle>
              <TextWrap type="h6">
                국내 최고의 파트너와 전국{" "}
                <CountText>
                  {isAnimationActive ? count.toLocaleString("ko-KR") : `0`}
                  {"+"}
                </CountText>
                곳의
                <br /> 충전소를 운영중입니다
              </TextWrap>
            </>
          }
          tablet={
            <>
              <SubTitle textCenter type="h3">
                Partner Site
              </SubTitle>
              <TextWrap type="h6">
                국내 최고의 파트너와 전국{" "}
                <CountText>
                  {isAnimationActive ? count.toLocaleString("ko-KR") : `0`}
                  {"+"}
                </CountText>
                곳의
                <br /> 충전소를 운영중입니다
              </TextWrap>
            </>
          }
          mobile={
            <>
              <SubTitle textCenter type="h3">
                Partner Site
              </SubTitle>
              <TextWrap type="h6">
                국내 최고의 파트너와 전국
                <br />
                <CountText>
                  {isAnimationActive ? count.toLocaleString("ko-KR") : `0`}
                  {"+"}
                </CountText>
                곳의 충전소를 <br />
                운영중입니다
              </TextWrap>
            </>
          }
        />

        <LogoWrap theme={{ isAnimation: isLogoWrapAnimation }}>
          {logoList.map((logo) => {
            return (
              <Logo
                key={logo}
                backgroundSrc={`/images/partnerLogo/${logo}.png`}
              />
            );
          })}
        </LogoWrap>
      </StyledCommonWrap>
    </StyledBaseSection>
  );
};

export default HomeSection09;

const StyledBaseSection = styled(BaseSection)`
  height: auto;
  padding: ${PC_PADDING_VERTICAL} ${PC_PADDING_HORIZON};

  ${media.MIN_PC} {
    padding: ${PC_PADDING_VERTICAL} ${MIN_PC_PADDING_HORIZON};
  }

  ${media.TABLET} {
    padding: ${TABLET_PADDING_VERTICAL} ${TABLET_PADDING_HORIZON};
  }

  ${media.MOBILE} {
    padding: ${MOBILE_PADDING_VERTICAL} ${MOBILE_PADDING_HORIZON};
  }
`;

const StyledCommonWrap = styled(CommonWrap)`
  display: flex;
  flex-direction: column;
  gap: 80px;
  position: relative;

  > div:nth-child(1) {
    ${(props) => cssTemplate.customFadeInDown(props)}
  }

  ${media.TABLET} {
    justify-content: center;
    align-items: center;
  }

  ${media.MOBILE} {
    gap: 40px;
  }
`;

const TextWrap = styled(Text)`
  ${typo.DISPLAY_04};
  text-align: center;
  margin-top: 16px;

  ${media.TABLET} {
    ${typo.DISPLAY_04}
  }

  ${media.MOBILE} {
    ${typo.HEADING_01}
    margin-top: 8px;
  }
`;

const CountText = styled.span`
  color: ${colors.PRIMARY};
`;

const LogoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${(props) => cssTemplate.customFadeInDown(props)}
  row-gap: 36px;
  column-gap: 24px;

  ${media.TABLET} {
    max-width: 525px;
    column-gap: unset;
    row-gap: 24px;
  }

  ${media.MOBILE} {
    max-width: 344px;
  }
`;

const Logo = styled(Icon)`
  display: inline-block;
  width: 100%;
  max-width: 220px;
  height: 80px;

  ${media.TABLET} {
    max-width: 105px;
    height: 38px;
  }
  ${media.MOBILE} {
    max-width: 77px;
    height: 28px;
  }
`;
