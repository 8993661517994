import Icon from "@components/atoms/Icon";
import Text from "@components/atoms/Text";
import BaseLayoutModal from "@components/template/BaseLayoutModal";
import ScreenResizing from "@components/template/Layout/ScreenResizing";
import { ChargerInfoData } from "@constants/home";
import { CommonModalProps } from "@constants/types";
import {
  ModalDescription,
  ModalTitle,
  ModalTitleWrap,
} from "@styles/CommonStyled";
import { colors, media, MOBILE_PADDING_HORIZON, typo } from "@styles/index";
import React from "react";
import styled from "styled-components";

interface ChargerInfoModalProps extends CommonModalProps {
  data?: ChargerInfoData;
}

const ChargerInfoModal: React.FC<ChargerInfoModalProps> = ({
  data,
  isModalOpen,
  handleModalClose,
}) => {
  if (!data) return <></>;
  const { src, chargerName, description, infos } = data;
  const screenHeight = window.innerHeight;

  return (
    <BaseLayoutModal
      isOpen={isModalOpen}
      handleModalClose={handleModalClose}
      modalPaddingDisabled
      height="90%"
    >
      <ScreenResizing
        pc={
          <>
            <ModalWrap>
              <ImgWrap>
                <Icon backgroundSrc={src} width={189} height={345} />
              </ImgWrap>
              <ModalContentWrap>
                <InfoWrap>
                  <ModalTitleWrap>
                    <ModalTitle>{chargerName}</ModalTitle>
                    <ModalDescription>{description}</ModalDescription>
                  </ModalTitleWrap>

                  <ChargerInfoChartWrap>
                    {infos.map(({ label, content }) => {
                      return (
                        <ChartInfoItem key={label}>
                          <InfoTitle>{label}</InfoTitle>
                          <InfoDescription>{content}</InfoDescription>
                        </ChartInfoItem>
                      );
                    })}
                  </ChargerInfoChartWrap>
                </InfoWrap>
              </ModalContentWrap>
            </ModalWrap>
          </>
        }
        tablet={
          <ModalWrap>
            <StyledModalHeaderWrap>
              <ModalTitleWrap>
                <ModalTitle>{chargerName}</ModalTitle>
                <ModalDescription>{description}</ModalDescription>
              </ModalTitleWrap>
              <ImgWrap>
                <Icon backgroundSrc={src} width={189} height={345} />
              </ImgWrap>
            </StyledModalHeaderWrap>
            <ModalContentWrap>
              <ChargerInfoChartWrap>
                {infos.map(({ label, content }) => {
                  return (
                    <ChartInfoItem key={label}>
                      <InfoTitle>{label}</InfoTitle>
                      <InfoDescription>{content}</InfoDescription>
                    </ChartInfoItem>
                  );
                })}
              </ChargerInfoChartWrap>
            </ModalContentWrap>
          </ModalWrap>
        }
        mobile={
          <ModalWrap>
            <StyledModalHeaderWrap>
              <ModalTitleWrap>
                <ModalTitle>{chargerName}</ModalTitle>
                <ModalDescription>{description}</ModalDescription>
              </ModalTitleWrap>
              <ImgWrap>
                <Icon backgroundSrc={src} width={189} height={345} />
              </ImgWrap>
            </StyledModalHeaderWrap>
            <ModalContentWrap>
              <ChargerInfoChartWrap>
                {infos.map(({ label, content }) => {
                  return (
                    <ChartInfoItem key={label}>
                      <InfoTitle>{label}</InfoTitle>
                      <InfoDescription>{content}</InfoDescription>
                    </ChartInfoItem>
                  );
                })}
              </ChargerInfoChartWrap>
            </ModalContentWrap>
          </ModalWrap>
        }
      />
    </BaseLayoutModal>
  );
};

export default ChargerInfoModal;

const ModalWrap = styled.div`
  display: flex;
  height: 100%;
  padding: 40px 100px 168px;
  gap: 50px;

  ${media.TABLET} {
    padding: unset;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    gap: 30px;
  }
  ${media.MOBILE} {
  }
`;

const StyledModalHeaderWrap = styled.div`
  ${media.TABLET} {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0 40px 0;
  }
  ${media.MOBILE} {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0 ${MOBILE_PADDING_HORIZON} 0;
  }
`;

const ImgWrap = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  max-width: 468px;
  width: 100%;
  height: 100%;

  ${media.TABLET} {
    max-width: unset;
  }
`;

const ModalContentWrap = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  max-width: 612px;
  width: 100%;
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;

  ${media.TABLET} {
    max-width: unset;
    border-top: 12px solid ${colors.GRAY_10};
    padding: 0 40px 40px;
  }
  ${media.MOBILE} {
    padding: 0 ${MOBILE_PADDING_HORIZON} 40px;
  }
`;

const InfoWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const ChargerInfoChartWrap = styled.ul`
  width: 100%;
  padding-bottom: 60px;

  & li:last-child {
    border-bottom: none;
  }

  ${media.TABLET} {
    padding-bottom: unset;
  }
`;

const ChartInfoItem = styled.li`
  display: flex;
  gap: 40px;
  max-width: 512px;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid ${colors.GRAY_09};
  ${media.TABLET} {
    max-width: unset;
  }
  ${media.MOBILE} {
    max-width: unset;
    gap: 24px;
  }
`;

const InfoTitle = styled(Text)`
  max-width: 100px;
  width: 100%;
  ${typo.HEADING_05}

  ${media.TABLET} {
    ${typo.HEADING_06}
    max-width: 90px;
  }
`;

const InfoDescription = styled(Text)`
  ${typo.BODY_05R};

  ${media.TABLET} {
    ${typo.BODY_06R}
  }
`;
