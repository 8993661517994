import { ICONS } from "@assets/index";
import { useBodyScrollLock } from "@hooks/useBodyScrollLock";
import useWindowSize from "@hooks/useWindowSize";
import { media } from "@styles/index";
import React, { useEffect } from "react";
import ReactModal, { Styles } from "react-modal";
import styled, { css } from "styled-components";
import Icon from "../../atoms/Icon";

interface BaseLayoutModalProps {
  children?: React.ReactNode;
  isOpen: boolean;
  innerWrapPadding?: string;
  modalPaddingDisabled?: boolean;
  height?: string;
  innerWrapStyle?: React.CSSProperties;
  unActiveScrollLock?: boolean;
  handleModalClose: () => void;
}

const BaseLayoutModal: React.FC<BaseLayoutModalProps> = ({
  children,
  isOpen,
  innerWrapPadding,
  modalPaddingDisabled,
  height,
  innerWrapStyle,
  unActiveScrollLock,
  handleModalClose,
  ...res
}) => {
  const { height: windowHeight } = useWindowSize();
  const { lockScroll, openScroll } = useBodyScrollLock();

  useEffect(() => {
    if (unActiveScrollLock) return;
    if (isOpen) {
      lockScroll();
    } else {
      openScroll();
    }
  }, [isOpen, lockScroll, openScroll, unActiveScrollLock]);

  const customStyles: Styles = {
    overlay: {
      background: "#00000040",
      zIndex: 9999,
    },
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={handleModalClose}
      style={customStyles}
      ariaHideApp={false}
      height={height}
      windowHeight={windowHeight}
      modalPaddingDisabled={modalPaddingDisabled}
      {...res}
    >
      <StyledDiv modalPaddingDisabled={modalPaddingDisabled}>
        <StyledIcon
          onClick={handleModalClose}
          backgroundSrc={ICONS.CLOSE}
          width={32}
          height={32}
        />
      </StyledDiv>
      <ModalInnerWrap
        innerWrapPadding={innerWrapPadding}
        style={innerWrapStyle}
      >
        {children}
      </ModalInnerWrap>
    </StyledModal>
  );
};

export default BaseLayoutModal;

const StyledModal = styled(ReactModal)<{
  modalPaddingDisabled?: boolean;
  height?: string;
  windowHeight: number;
}>`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: 1080px;
  width: 100%;
  background: white;
  height: ${({ height, windowHeight }) =>
    height ? height : windowHeight < 880 ? "90%" : "auto"};

  padding: ${({ modalPaddingDisabled }) =>
    modalPaddingDisabled ? "24px 0 0 0" : "24px"};
  border-radius: 16px;
  box-shadow: 0px 4px 32px rgba(53, 58, 63, 0.1);
  overflow: hidden;

  :focus {
    outline: none;
  }

  ${media.TABLET} {
    max-width: unset;
    border-radius: unset;
    height: 100%;
  }
`;

const StyledDiv = styled.div<{
  modalPaddingDisabled?: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  ${({ modalPaddingDisabled }) =>
    modalPaddingDisabled &&
    css`
      padding: 0 24px;
    `};
`;

const StyledIcon = styled(Icon)`
  /* tablet, mobile */
  cursor: pointer;
`;

const ModalInnerWrap = styled.div<{
  innerWrapPadding?: string;
}>`
  ${({ innerWrapPadding }) =>
    innerWrapPadding &&
    css`
      padding: ${innerWrapPadding};
    `}
  height:100%;

  ${media.TABLET} {
    height: 95%;
    overflow: scroll;
    padding: 0;
  }
`;
